// 华凯
import { StyledPropsTheme } from '../../types';
import parent from './standard';

export default Object.assign({}, parent, {
	COLOR_PRIMARY: '#FEC02A', // 标准色
	COLOR_PRIMARY_LIGHT_O75: 'rgba(254, 192, 42, 0.75)', // 标准色75%透明
	COLOR_PRIMARY_LIGHT_010: 'rgba(254, 192, 42, 0.1)',
	COLOR_PRIMARY_LIGHT: '#FEC02B', // 标准色淡10%
	INPUT_FOCUS_BORDER_COLOR: 'rgba(254, 192, 42, 0.8)', // 输入框获取焦点后边框色
	TOGGLE_TRUE_BORDER_COLOR: '#FEC02A',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#FEC02A'
}) as StyledPropsTheme;

