import { Utils } from '../common';
import React from 'react';

export const VisibleWidget = (SuperWidget: any) => {
	if (Utils.isFunction(SuperWidget)) {
		return class extends SuperWidget {
			render() {
				// 没有函数和函数返回true
				if (!this.isVisible || this.isVisible()) {
					return super.render();
				} else {
					return <React.Fragment />;
				}
			}
			getOriginalWidget() {
				return SuperWidget;
			}
		};
	} else {
		return SuperWidget;
	}
};
