import { ButtonProps, StyledProps } from '../../types';
import { Styled } from '../../common/3rd';
import { Desktop } from '..';

const Button = Styled.a`
	display: block;
	position: relative;
	text-align: center;
	cursor: pointer;
	user-select: none;
	border-radius: ${props => props.theme.BORDER_RADIUS}px;
	height: ${props => props.theme.BTN_HEIGHT}px;
	padding: ${props => `${props.theme.BTN_VERTICAL_PADDING}px ${props.theme.BTN_HORIZONTAL_PADDING}px`};
	line-height: ${props => props.theme.BTN_HEIGHT - props.theme.BTN_VERTICAL_PADDING * 2}px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	${(props: ButtonProps & StyledProps) => {
		switch (props.type) {
			case 'primary':
				return `
					background-color: ${props.theme.COLOR_PRIMARY};
					color: ${props.theme.COLOR_REVERSED};
					${Desktop.only`
						&:hover {
							background-color: ${props.theme.COLOR_PRIMARY_LIGHT};
						}
					`}
				`;
			case 'danger':
				return `
					background-color: ${props.theme.COLOR_DANGER};
					color: ${props.theme.COLOR_REVERSED};
					${Desktop.only`
						&:hover {
							background-color: ${props.theme.COLOR_DANGER_LIGHT};
						}
					`}
				`;
			case 'waive':
				return `
					background-color: ${props.theme.COLOR_WAIVE};
					color: ${props.theme.COLOR_REVERSED};
					${Desktop.only`
						&:hover {
							background-color: ${props.theme.COLOR_WAIVE_LIGHT};
						}
					`}
				`;
			default:
		}
	}}
	${props => (props.css ? props.css(props, (props as any).theme) : '')}
`;

const Link = Styled.a`
	display: block;
	position: relative;
	cursor: pointer;
	height: ${props => props.theme.BTN_HEIGHT}px;
	line-height: ${props => props.theme.BTN_HEIGHT - props.theme.BTN_VERTICAL_PADDING * 2}px;
	padding: ${props => `${props.theme.BTN_VERTICAL_PADDING}px 0`};
	text-decoration: underline;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	
	&:hover {
		color: ${props => props.theme.COLOR_PRIMARY};
	}

	${(props: StyledProps) => (props.css ? props.css(props, (props as any).theme) : '')}
`;

export const BUTTON = Button;
export const LINK = Link;
