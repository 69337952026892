import { VisibleWidget } from './visible-widget-wrapper';

export class ComponentRegistry {
	private all = {} as { [propName: string]: any };
	private readonlyAll = [] as any[];
	register(
		name:
			| string
			| {
					[propName: string]: any;
			  },
		comp?: any,
		readonly?: boolean
	): this {
		if (typeof name === 'string') {
			this.all[name] = VisibleWidget(comp);
		} else {
			Object.keys(name).forEach(key => {
				this.all[key] = VisibleWidget(name[key]);
			});
		}
		return this;
	}
	find(name: string): any {
		const comp = this.all[name];
		if (comp == null) {
			throw new Error(`Component[${name}] not found.`);
		}
		return comp;
	}
	asReadOnly(comp: any, ...rest: any[]): this {
		this.readonlyAll.push(comp);
		rest.forEach(one => this.readonlyAll.push(one));
		return this;
	}
	isReadOnly(comp: any): boolean {
		return (
			this.readonlyAll.includes(comp) ||
			// 组件被包装过
			(comp.getOriginalWidget
				? this.readonlyAll.includes(comp.getOriginalWidget())
				: false)
		);
	}
}
export const DeskComponentRegistry = new ComponentRegistry();
export const MobileComponentRegistry = new ComponentRegistry();
