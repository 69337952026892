import Alert from './alert';
import { DOM } from '../common';
import { ReactDOM } from '../common/3rd';
import { CheckableWidgetCheckResult } from '../types';
import Widget from './widget';

const runtimeComponentsRegistry = [] as Widget<any, any, any>[];

export const registerIntoRuntime = (widget: Widget<any, any, any>): void => {
	if (!runtimeComponentsRegistry.includes(widget)) {
		runtimeComponentsRegistry.push(widget);
	}
};

export const unregisterFromRuntime = (widget: Widget<any, any, any>): void => {
	const index = runtimeComponentsRegistry.indexOf(widget);
	if (index !== -1) {
		runtimeComponentsRegistry.splice(index, 1);
	}
};

export const getAllRuntimeComponents = (): Widget<any, any, any>[] => {
	return Array.from(runtimeComponentsRegistry);
};

export const findComponentsById = (id: string): Widget<any, any, any>[] => {
	return runtimeComponentsRegistry.filter((comp: any) => {
		const propName = comp.getPropName && comp.getPropName();
		return propName === id;
	});
};
export const getComponentById = (id: string): Widget<any, any, any> | undefined => {
	return runtimeComponentsRegistry.find((comp: any) => {
        const propName = comp.getPropName && comp.getPropName();
		return propName === id;
	});
};

const alertCheckFailure = (msg?: string): Promise<void> => {
	return Alert.warn(msg ? msg : '数据校验未通过, 请检查栏位填入值.');
};

/**
 * 整体校验, 如果校验失败, 返回rejected promise. 将失败组件位置都带出来
 * 如果校验成功, 返回resolved promise, 并且没有参数
 */
export const checkAllRuntimeComponents = (
	options: {
		stage?: string;
		alert?: false;
		msg?: string;
	} = {}
): Promise<CheckableWidgetCheckResult[] | void> => {
	const { stage, alert = true, msg } = options;

	return new Promise((resolve, reject) => {
		Promise.all(
			runtimeComponentsRegistry
				.filter(child => child != null)
				.map((child: any) => {
					if (child.checkData) {
						return child.checkData(stage);
					} else {
						return null;
					}
				})
				.filter(checkResult => checkResult != null)
		).then((results: CheckableWidgetCheckResult[]) => {
			const fails = results
				// 过滤掉通过校验的
				.filter((result: CheckableWidgetCheckResult) => result.rule != null)
				// 计算出具体dom的位置
				.map((result: CheckableWidgetCheckResult) => {
					const element = ReactDOM.findDOMNode(result.where);
					return {
						pos: DOM.offset(element as any),
						where: element,
						react: result.where
					};
				})
				.sort((a, b) => {
					if (a.pos.top < b.pos.top) {
						return -1;
					} else if (a.pos.top > b.pos.top) {
						return 1;
					} else {
						return a.pos.left - b.pos.left;
					}
				});
			if (fails.length > 0) {
				// 弹框有重刷页面动作, 因此必须等弹框完成之后, 重新计算需要被focus的组件
				if (alert) {
					const element = fails[0].where as HTMLElement;
					fails[0].react.focus && fails[0].react.focus();
					alertCheckFailure(msg).then(() => {
						element.scrollIntoView(false);
						if (window.scrollY + window.innerHeight - DOM.offset(element).top < 100) {
							window.scrollTo(window.scrollX, window.scrollY + 100);
						}
					});
				}
				reject(fails);
			} else {
				resolve();
			}
		});
	});
};
