import { Envs, Utils } from '@common';
import { Widget } from '@component';
import { PageComponents, StyledPropsCss } from '@my-types';
import { React } from '@common/3rd';

/**
 * 中间页面
 */
class MiddlePage<P extends StyledPropsCss, S, C extends PageComponents> extends Widget<P, S, C>{

    constructor(props: any, context?: any) {
        super(props, context);
        document.title = this.getHtmlTitle() || (process.env.REACT_APP_DEFAULT_TITLE as string);
        this.redirectPage();
    }
    
    redirectPage() {
        //通过免登录实现
        //商城官网登录的话没有免登录queryString,则需要缓存中的租户判断,
        //自动跳转到登录页
        const pathname = window.location.pathname;
        if (new RegExp("^/csms/").test(pathname)) {
            const { tenantCode = '' } = Envs.findAccount() || {};
            const { delegated = '' } = Utils.fromQueryString() || {};

            if (Utils.isTrue(Envs.getEnvValue('JUMP_TO_EPLBKS'))
                && ((Utils.isString(delegated) && /^EPLBKS\.[0-9a-zA-Z]+/.test(delegated))
                    || (Utils.isString(tenantCode) && /^EPLBKS$/.test(tenantCode)))) {
                //跳转到csms-eplbks2
                window.location.replace(
                    window.location.href.replace('/csms/', '/csms-eplbks2/')
                );
            } else {
                //跳转到csms2
                window.location.replace(
                    window.location.href.replace('/csms/', '/csms2/')
                );
            }
        } else if (new RegExp("^/csms2/").test(pathname)
            || new RegExp("^/csms-eplbks2/").test(pathname)
            || new RegExp("^/csms-eplbks/").test(pathname)) {
            return;
        } else {
            if (Envs.isDesk()) {
                //跳转到中台登陆页
                window.location.replace(
                    Envs.getContextLocation('/mo/sign/in', {
                        protocolHost: true,
                    })
                );
            } else {
                window.location.replace(
                    Envs.getContextLocation('/mobile/sign/in', {
                        protocolHost: true,
                    }).replace('/csms/', '/csms2/')
                );
            }
        }
    }

    render() {
        return <div />
    }


    protected initComponents(): C {
        return {} as C;
    }
    protected getHtmlTitle(): string | null {
        return '';
    }

}

export default MiddlePage;
