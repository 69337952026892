import { StyledPropsCss } from '../../types';
import { Styled } from '../../common/3rd';

const Caret = (color: string) => {
	const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="${color}" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>`;
	return `data:image/svg+xml;charset=utf8,${encodeURIComponent(svg)}`;
};

const Select = Styled.select`
	display: block;
	position: relative;
	border: ${props => props.theme.BORDER};
	border-radius: ${props => props.theme.BORDER_RADIUS}px;
	outline: none;
	margin: 0;
	background-color: ${props => props.theme.INPUT_BACKGRAOUND_COLOR};
	color: ${props => props.theme.FONT_COLOR};
	font-family: ${props => props.theme.FONT_FAMILY};
	font-weight: ${props => props.theme.FONT_WEIGHT};
	font-size: inherit;
	padding: ${props => props.theme.INPUT_VERTICAL_PADDING}px ${props =>
	props.theme.SELECT_CARET_SIZE +
	props.theme.SELECT_CARET_RIGHT * 2}px ${props =>
	props.theme.INPUT_VERTICAL_PADDING}px ${props =>
	props.theme.INPUT_HORIZONTAL_PADDING}px;
	min-height: ${props => props.theme.INPUT_HEIGHT}px;
	cursor: pointer;
	appearance: none;
	background-image: url(${props => Caret(props.theme.SELECT_CARET_COLOR)});
	background-position: right ${props => props.theme.SELECT_CARET_RIGHT}px top 50%;
	background-repeat: no-repeat;
	background-size: ${props => props.theme.SELECT_CARET_SIZE}px;
   white-space: normal;
	&[disabled],
	&[readOnly],
	&[readonly] {
		background-color: ${props => props.theme.COLOR_DISABLED};
		background-image: url(${props => Caret(props.theme.COLOR_DISABLED)});
	}

	${(props: StyledPropsCss) =>
		props.css ? props.css(props, (props as any).theme) : ''}}
`;

const SelectUnderline = Styled(Select)`
	border-top-color: transparent;
	border-left: 0;
	border-right: 0;
	padding-left: 0;
	border-radius: 0;
	&:focus {
		border-bottom-color: ${props => props.theme.COLOR_PRIMARY};
		background-image: url(${props => Caret(props.theme.COLOR_PRIMARY)});
	}
`;

export const SELECT = Select;
export const SelectUL = SelectUnderline;
export const SelectCaret = Caret;
