import { StyledPropsTheme } from '../../types';
import parent from './standard';

export default Object.assign({}, parent, {
	COLOR_PRIMARY: '#9e1d22', // 标准色
	COLOR_PRIMARY_LIGHT_O75: 'rgba(158, 29, 34, 0.75)', // 标准色75%透明
	COLOR_PRIMARY_LIGHT_010: 'rgba(158, 29, 34, 0.1)',
	COLOR_PRIMARY_LIGHT: '#F6E9E9', // 标准色淡10%
	INPUT_FOCUS_BORDER_COLOR: 'rgba(158, 29, 34, 0.8)', // 输入框获取焦点后边框色
	TOGGLE_TRUE_BORDER_COLOR: '#9e1d22',
	TOGGLE_TRUE_BACKGROUND_COLOR: '#9e1d22',
}) as StyledPropsTheme;
