import { Utils } from '../common';
import { Checker, Filter, Modeller } from '../data-model';
import {
	CheckableWidgetCheckResult,
	CheckableWidgetProps,
	CheckableWidgetState,
	CheckRule,
	EditableFilterable,
	Filterable
} from '../types';
import { ModelWidget } from './model-widget';

/**
 * 可以被校验的组件. 适用于组件内部可输入的场景.
 */
abstract class CheckableWidget<P extends CheckableWidgetProps, S extends CheckableWidgetState, C> extends ModelWidget<P,
	S,
	C> {
	protected installModelListeners(): this {
		super.installModelListeners();
		this.installEditableListeners();
		return this;
	}

	protected uninstallModelListeners(): this {
		super.uninstallModelListeners();
		this.uninstallEditableListeners();
		return this;
	}

	protected forceUpdateForEditable = () => {
		this.forceUpdate();
	};

	protected installEditableListeners(): this {
		if (this.getModel().$$proxied) {
			return this.careForEditableListeners(item => {
				Modeller.asProxied(item.model).$$on(item.propName, this.forceUpdateForEditable);
			});
		} else {
			return this;
		}
	}

	protected uninstallEditableListeners(): this {
		if (this.getModel().$$proxied) {
			return this.careForEditableListeners(item =>
				Modeller.asProxied(item.model).$$off(item.propName, this.forceUpdateForEditable)
			);
		} else {
			return this;
		}
	}

	private careForEditableListeners(handler: (item: { propName: string; model: any }) => void): this {
		const editable = this.getEditableTrigger();
		if (!Utils.isBoolean(editable)) {
			Filter.getConcernedIdsOfFilters({
				filters: editable,
				root: this.getRootModel(),
				model: this.getModel(),
				caller: this
			}).forEach(handler);
		}
		return this;
	}

	/**
	 * 可被校验组件必须可以强制获取焦点
	 */
	abstract focus(): void;

	isCheckedAnyway(): boolean {
		// 从未设置过这个属性, 就是从未校验过
		return !Utils.isUndefined(this.state.checkFailOnWhatever);
	}

	isCheckFailOnWhatever(): boolean {
		return this.state.checkFailOnWhatever === true;
	}

	getCheckFailMessage(): string | undefined {
		return this.state.checkFailMsg;
	}

	protected isEditable(): boolean {
		const readonly = this.getEditableTrigger();
		if (Utils.isBoolean(readonly)) {
			// readonly和editable是反的
			return !readonly;
		} else if (readonly.length === 0) {
			return true;
		} else {
			const matchedFilter = this.matchFilter(readonly) as EditableFilterable;
			if (matchedFilter == null) {
				// 没有任何过滤器被匹配到, 可编辑
				return true;
			} else {
				// 只有readonly被显式的定义为false, 那么editable是true
				// 否则editable就是false
				return matchedFilter.do === false;
			}
		}
	}

	protected getEditableTrigger(): EditableFilterable[] | boolean {
		const props = this.props as any;
		const readonly = props.readonly || props.disabled;
		if (Utils.isBoolean(readonly)) {
			return readonly;
		} else {
			return Utils.toArray(readonly as any).map((filter: string | EditableFilterable) => {
				if (Utils.isString(filter)) {
					return { on: filter, do: true } as EditableFilterable;
				} else {
					return filter;
				}
			});
		}
	}

	/**
	 * 获取校验规则,
	 * 如果参数定义为boolean/null/undefined, 则返回空数组;
	 * 否则返回规则数组
	 */
	protected getCheckRules(): CheckRule[] {
		const rules = this.props.check;
		if (rules == null || Utils.isBoolean(rules)) {
			return [];
		} else {
			return Utils.toArray(rules as CheckRule | CheckRule[]).flat().filter(item => item);
		}
	}

	/**
	 * 获取校验开启前置条件, 无论如何返回数组
	 */
	protected getTriggerCheckOn(): Filterable[] {
		return Utils.toArray(this.props.triggerCheckOn as any).map((filter: string | Filterable) => {
			if (Utils.isString(filter)) {
				return { on: filter } as Filterable;
			} else {
				return filter;
			}
		});
	}

	protected shouldCheckTriggerred(): boolean {
		const triggers = this.getTriggerCheckOn();
		if (triggers.length > 0) {
			return this.matchFilter(triggers) != null;
		} else {
			// 没有设置过校验前置条件, 则认为校验开启
			return true;
		}
	}

	protected getCheckStages(): string[] {
		return Utils.toArray(this.props.checkStages);
	}

	protected abstract doAfterCheckCompleted(): this;

	/**
	 * 校验数据, 校验完成之后返回一个Promise.
	 * 不管校验通过不通过, 总是返回一个resolve的Promise.
	 * 如果校验没有通过, 则将没有通过的rule对象通过Promise返回.
	 * 如果校验通过, 则Promise仅携带组件, 不会有rule.
	 */
	protected checkData(stage?: string): Promise<CheckableWidgetCheckResult> {
		if (!Utils.isEmpty(stage)) {
			const stages = this.getCheckStages();
			if (!stages.includes(stage)) {
				// 指定了校验阶段, 但是与声明不匹配, 不需要校验
				return Promise.resolve({ where: this });
			}
		}

		if (!this.shouldCheckTriggerred()) {
			// 如果不能满足校验前置条件, 则认为通过校验
			return Promise.resolve({ where: this });
		}
		const rules = this.getCheckRules();
		if (rules.length === 0 || !this.isEditable()) {
			// 没有规则需要校验
			return Promise.resolve({ where: this });
		}

		return new Promise(resolve => {
			let ret;
			const failRule = rules.find(rule => {
				if (rule.on) {
					// 有前置条件, 可以当做一个filter来处理
					const matchedFilter = this.matchFilter(rule);
					if (matchedFilter) {
						ret = this.checkRule(rule);
						// 没有通过校验
						if (ret !== true) {
							return true;
						}
					}
				} else {
					ret = this.checkRule(rule);
					// 没有通过校验
					if (ret !== true) {
						return true;
					}
				}
				return false;
			});

			// 由于状态更新可能会影响到容器,
			// 因此强制刷新本组件结束后才返回, 以保证state绝对正确
			if (failRule != null) {
				// 有错
				const checkFailMsg = failRule.msg ? failRule.msg : (Utils.isString(ret) ? ret : '校验未通过, 请检查数据.');
				this.setState({ checkFailOnWhatever: true, checkFailMsg }, () => {
						resolve({ rule: failRule, where: this });
					}
				);
			} else {
				// 没错
				this.setState({ checkFailOnWhatever: false, checkFailMsg: undefined }, () => {
						resolve({ where: this });
					}
				);
			}
		}).then((arg: any) => {
			this.doAfterCheckCompleted();
			return arg;
		});
	}

	/**
	 * 校验指定的规则
	 */
	protected checkRule(rule: CheckRule): string | boolean {
		if (Utils.isBoolean(rule.do)) {
			return rule.do;
		} else if (Utils.isFunction(rule.do)) {
			return rule.do.call(this, {
				value: this.getValueFromModel(),
				model: this.getModel(),
				root: this.getRootModel(),
				arrayHolder: this.getArrayHolder(),
				caller: this
			});
		} else {
			return Checker.check({
				rule: rule,
				value: this.getValueFromModel(),
				model: this.getModel(),
				root: this.getRootModel(),
				arrayHolder: this.getArrayHolder(),
				caller: this
			});
		}
	}
}

export default CheckableWidget;
