import React from 'react';
import styled from 'styled-components';
import { StyledF } from '../../common/3rd';

const Svg = styled.svg.attrs({
    'data-widget': 'svg',
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;

const StyledSvgIcon = styled(Svg)`
    width: 20px;
    height: 100%;
    color: ${props => props.theme.COLOR_PRIMARY};
`;

type SvgIconProps = {
    icon: string;
    fill?: string;
    className?: string;
    style?: React.CSSProperties;
}

const BaoProps = {
    viewBox: "64 64 896 896",
    d: "M519.9 358.8h97.9v41.6h-97.9zm347-188.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM411.3 656h-.2c0 4.4-3.6 8-8 8h-37.3c-4.4 0-8-3.6-8-8V471.4c-7.7 9.2-15.4 17.9-23.1 26a6.04 6.04 0 0 1-10.2-2.4l-13.2-43.5c-.6-2-.2-4.1 1.2-5.6 37-43.4 64.7-95.1 82.2-153.6 1.1-3.5 5-5.3 8.4-3.7l38.6 18.3c2.7 1.3 4.1 4.4 3.2 7.2a429.2 429.2 0 0 1-33.6 79V656zm296.5-49.2l-26.3 35.3a5.92 5.92 0 0 1-8.9.7c-30.6-29.3-56.8-65.2-78.1-106.9V656c0 4.4-3.6 8-8 8h-36.2c-4.4 0-8-3.6-8-8V536c-22 44.7-49 80.8-80.6 107.6a5.9 5.9 0 0 1-8.9-1.4L430 605.7a6 6 0 0 1 1.6-8.1c28.6-20.3 51.9-45.2 71-76h-55.1c-4.4 0-8-3.6-8-8V478c0-4.4 3.6-8 8-8h94.9v-18.6h-65.9c-4.4 0-8-3.6-8-8V316c0-4.4 3.6-8 8-8h184.7c4.4 0 8 3.6 8 8v127.2c0 4.4-3.6 8-8 8h-66.7v18.6h98.8c4.4 0 8 3.6 8 8v35.6c0 4.4-3.6 8-8 8h-59c18.1 29.1 41.8 54.3 72.3 76.9 2.6 2.1 3.2 5.9 1.2 8.5z"
}

const ScanProps = {
    viewBox: "0 0 32 32",
    d: "M20 4h8v6.667h-2.667v-4h-5.333v-2.667zM12 4v2.667h-5.333v4h-2.667v-6.667h8zM20 28v-2.667h5.333v-4h2.667v6.667h-8zM12 28h-8v-6.667h2.667v4h5.333v2.667zM4 14.667h24v2.667h-24v-2.667z"
}

const CustomerProps = {
    viewBox: "0 0 32 32",
    d: "M18.667 19.003v2.787c-0.794-0.29-1.711-0.458-2.667-0.458-4.418 0-8 3.582-8 8 0 0.001 0 0.001 0 0.002v-0l-2.667-0.001c0-0.001 0-0.002 0-0.003 0-5.891 4.776-10.667 10.667-10.667 0.948 0 1.866 0.124 2.741 0.355l-0.074-0.017zM16 17.333c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM16 14.667c2.947 0 5.333-2.387 5.333-5.333s-2.387-5.333-5.333-5.333-5.333 2.387-5.333 5.333 2.387 5.333 5.333 5.333zM25.891 22.667h4.781v2.667h-4.781l2.439 2.437-1.885 1.887-5.657-5.657 5.657-5.657 1.885 1.887-2.439 2.437z"
}

const BankInfoProps = {
    viewBox: "64 64 896 896",
    d: "M928 160H96c-17.7 0-32 14.3-32 32v160h896V192c0-17.7-14.3-32-32-32zM64 832c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V440H64v392zm579-184c0-4.4 3.6-8 8-8h165c4.4 0 8 3.6 8 8v72c0 4.4-3.6 8-8 8H651c-4.4 0-8-3.6-8-8v-72z"
}

const TrashProps = {
    viewBox: "0 0 32 32",
    d: "M9.333 5.333v-2.667h13.333v2.667h6.667v2.667h-2.667v20c0 0.736-0.597 1.333-1.333 1.333v0h-18.667c-0.736 0-1.333-0.597-1.333-1.333v0-20h-2.667v-2.667h6.667zM8 8v18.667h16v-18.667h-16zM12 12h2.667v10.667h-2.667v-10.667zM17.333 12h2.667v10.667h-2.667v-10.667z"
}

const BellProps = {
    viewBox: "0 0 32 32",
    d: "M21.225 20.889v-4.164c0-2.317-2.521-4.725-5.333-4.725s-5.333 2.408-5.333 4.725v4.164h10.667zM23.003 16.725v5.941h-14.222v-5.941c0-3.173 3.184-6.503 7.111-6.503s7.111 3.33 7.111 6.503zM15.892 3.111c0.491 0 0.889 0.398 0.889 0.889v1.778c0 0.491-0.398 0.889-0.889 0.889s-0.889-0.398-0.889-0.889v-1.778c0-0.491 0.398-0.889 0.889-0.889zM21.225 4.889c0.425 0.245 0.571 0.789 0.325 1.214l-0.889 1.54c-0.245 0.425-0.789 0.571-1.214 0.325s-0.571-0.789-0.325-1.214l0.889-1.54c0.245-0.425 0.789-0.571 1.214-0.325zM25.517 8.444c0.347 0.347 0.347 0.91 0 1.257l-1.257 1.257c-0.347 0.347-0.91 0.347-1.257 0s-0.347-0.91 0-1.257l1.257-1.257c0.347-0.347 0.91-0.347 1.257 0zM7.003 28c0-0.491 0.398-0.889 0.889-0.889h16c0.491 0 0.889 0.398 0.889 0.889s-0.398 0.889-0.889 0.889h-16c-0.491 0-0.889-0.398-0.889-0.889zM10.32 4.889c0.425-0.245 0.969-0.1 1.214 0.325l0.889 1.54c0.245 0.425 0.1 0.969-0.325 1.214s-0.969 0.1-1.214-0.325l-0.889-1.54c-0.245-0.425-0.1-0.969 0.325-1.214zM6.482 8.444c0.347-0.347 0.91-0.347 1.257 0l1.257 1.257c0.347 0.347 0.347 0.91 0 1.257s-0.91 0.347-1.257 0l-1.257-1.257c-0.347-0.347-0.347-0.91 0-1.257z"
}

export const SvgIcon = (options: SvgIconProps & React.SVGAttributes<SVGSVGElement>) => {
    const {
        icon,
        fill = 'currentColor',
        onClick,
        className,
        style = {}
    } = options;

    let iconProps;
    switch(icon) {
        case 'bao':
            iconProps = BaoProps;
            break;
        case 'scan':
            iconProps = ScanProps;
            break;
        case 'customer':
            iconProps = CustomerProps;
            break;
        case 'bankinfo':
            iconProps = BankInfoProps;
            break;
        case 'trash':
            iconProps = TrashProps;
            break;
        case 'bell':
            iconProps = BellProps;
            break;
        default:
            break;
    }

    return <StyledSvgIcon viewBox={iconProps.viewBox} className={className} style={style} onClick={onClick}>

        <path
            fill={fill}
            d={iconProps.d}
        />
    </StyledSvgIcon>;
};

