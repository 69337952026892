import { Ajax, Consts, Envs, Errors, PATH, Storage, Utils } from '../common';
import React from 'react';
import {
    AjaxResponse,
    AjaxResponseErrorItem,
    IWidgetAjaxHelper,
    IWidgetRouterHelper,
    OPTIONS,
    StyledPropsTheme,
    WidgetHelped,
    WidgetHelpers,
    WidgetProps
} from '../types';
import Alert from './alert';
import { registerIntoRuntime, unregisterFromRuntime } from './runtime-components';
// import { stopRecord } from '../common/glutton/glutton-util';


/**
 * widget router helper
 */
class WidgetRouterHelper implements IWidgetRouterHelper {
    private widget: Widget<any, any, any>;

    constructor(widget: Widget<any, any, any>) {
        this.widget = widget;
    }
    getWidget(): Widget<any, any, any> {
        return this.widget;
    }
    forward(): this {
        this.getHistory().goForward();
        return this;
    }
    back(): this {
        this.getHistory().goBack();
        return this;
    }
    go(steps: number): this {
        this.getHistory().go(steps);
        return this;
    }
    getHistory(): any {
        return Envs.application()!.getHistory();
    }
    /**
     * 使用react router时, 有免登录, 需要自动补上免登录信息
     */
    redirect(to: string, push: boolean = false) {
        if (Envs.isStandaloneAndDirectAccess()) {
            const questionMark = to.indexOf('?');
            const current = Utils.fromQueryString();
            if (questionMark !== -1) {
                // 判断url上是否存在免登录信息, 如果不存在加上, 存在不处理
                const next = Utils.fromQueryString(to.substring(questionMark + 1));
                if (Envs.isTatToken()) {
                    if (!next.ticket) {
                        const authQS = Utils.toQueryString({
                            ticket: Storage.Auth.session().get(Consts.AUTH_KEY)
                        });
                        to += `&${authQS}`;
                    }
                } else if (!(next.delegated && next.ticket)) {
                    // 不存在
                    const authQS = Utils.toQueryString({
                        delegated: current.delegated,
                        ticket: current.ticket
                    });
                    to += `&${authQS}`;
                }
            } else {
                const authQS = Utils.toQueryString({
                    delegated: current.delegated,
                    ticket: current.ticket
                });
                to += `?${authQS}`;
            }
        }
        if (Envs.os.ios && Envs.os.wechat) {
            // if (Envs.os.ios) {
            // 微信下使用react-router会导致分享的url不是标准url
            // 因此必须使用原生方式改变URL, 会导致页面重载
            // FIXME 等待微信修复
            if (push) {
                window.location.assign(Envs.getContextLocation(to, { protocolHost: true }));
            } else {
                window.location.replace(Envs.getContextLocation(to, { protocolHost: true }));
            }
        } else {
            const history = this.getHistory();
            if (push) {
                if (new RegExp("^" + PATH.MOBILE_POLICY).test(to)) {
                    history.push(to);
                    Storage.Share.holdFirstUrl();
                } else {
                    window.location.assign(Envs.getContextLocation(to, { protocolHost: true }));
                }
            } else {
                if (new RegExp("^" + PATH.MOBILE_POLICY).test(to)) {
                    history.replace(to);
                    Storage.Share.holdFirstUrl();
                } else {
                    window.location.replace(Envs.getContextLocation(to, { protocolHost: true }));
                }
            }
        }
    }

    protogeneRedirect(to: string, push: boolean = false) {
        if (Envs.isStandaloneAndDirectAccess()) {
            const questionMark = to.indexOf('?');
            const current = Utils.fromQueryString();
            if (questionMark !== -1) {
                // 判断url上是否存在免登录信息, 如果不存在加上, 存在不处理
                const next = Utils.fromQueryString(to.substring(questionMark + 1));
                if (Envs.isTatToken()) {
                    if (!next.ticket) {
                        const authQS = Utils.toQueryString({
                            ticket: Storage.Auth.session().get(Consts.AUTH_KEY)
                        });
                        to += `&${authQS}`;
                    }
                } else if (!(next.delegated && next.ticket)) {
                    // 不存在
                    const authQS = Utils.toQueryString({
                        delegated: current.delegated,
                        ticket: current.ticket
                    });
                    to += `&${authQS}`;
                }
            } else {
                const authQS = Utils.toQueryString({
                    delegated: current.delegated,
                    ticket: current.ticket
                });
                to += `?${authQS}`;
            }
        }
        const history = this.getHistory();
        if (push) {
            if (new RegExp("^" + PATH.MOBILE_POLICY).test(to)) {
                history.push(to);
                Storage.Share.holdFirstUrl();
            } else {
                window.location.assign(Envs.getContextLocation(to, { protocolHost: true }));
            }
        } else {
            if (new RegExp("^" + PATH.MOBILE_POLICY).test(to)) {
                history.replace(to);
                Storage.Share.holdFirstUrl();
            } else {
                window.location.replace(Envs.getContextLocation(to, { protocolHost: true }));
            }
        }
    }

    /**
     * 模仿history, 新增一个新的
     */
    pushRedirect(to: string): void {
        this.redirect(to, true);
    }

    pushProtogeneRedirect(to: string): void {
        this.protogeneRedirect(to, true);
    }

    /**
     * 模仿history, 删除原来的, 再保存新的
     */
    replaceRedirect(to: string): void {
        this.redirect(to, false);
    }

    //都使用react-router方式跳转，有ios分享问题，注意使用场合，为了解决内部跳转，Storage丢失问题
    replaceProtogeneRedirect(to: string): void {
        this.protogeneRedirect(to, false);
    }


    async gluttonReplace(to: string): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.location.replace(to);
        }
    }

    async gluttonAssign(to: string): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.location.assign(to);
        }
    }

    async gluttonHistoryGo(index: number = -1): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.history.go(index);
        }
    }

    async gluttonHistoryBack(): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.history.back();
        }
    }

    async windowReplace(to: string): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.location.replace(to);
        }
    }

    async windowAssign(to: string): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.location.assign(to);
        }
    }

    async windowHistoryGo(index: number = -1): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.history.go(index);
        }
    }

    async windowHistoryBack(): Promise<void> {
        //回朔需要手动调用stop
        try {
            // await stopRecord();
        } catch (e) {
            console.error(e);
        } finally {
            window.history.back();
        }
    }

}

/**
 * widget ajax helper
 */
class WidgetAjaxHelper implements IWidgetAjaxHelper {
    private widget: Widget<any, any, any>;

    constructor(widget: Widget<any, any, any>) {
        this.widget = widget;
    }
    getWidget(): Widget<any, any, any> {
        return this.widget;
    }
    private doAjax(url: string, data: any, options: OPTIONS = {}, action: Function): Promise<AjaxResponse> {
        return new Promise((resolve, reject) => {
            if (options.loading === true) {
                Alert.loading();
            }

            action
                .call(Ajax, url, data, options)
                .then((response: AjaxResponse) => {
                    if (options.loading === true) {
                        Alert.hide();
                    }
                    resolve(response);
                })
                .catch((error: AjaxResponse) => {
                    if (options.loading === true) {
                        Alert.hide();
                    }
                    if (error.status === 0) {
                        Alert.error(['网络服务异常, 请稍候再试.', '更快解决? 请与系统管理员联系.']);
                    }

                    !(options.ignore4xx || options.ignoreFail) && this.on4xx(error);
                    !(options.ignore5xx || options.ignoreFail) && this.on5xx(error);
                    reject(error);
                });
        });
    }
    post(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse> {
        return this.doAjax(url, data, options, Ajax.post);
    }
    get(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse> {
        return this.doAjax(url, data, options, Ajax.get);
    }
    /**
     * arguments are instances of FailFastPromise
     */
    all(promises: Promise<any>[]): Promise<any[]> {
        return Ajax.all(promises);
    }
    ajax(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse> {
        return this.doAjax(url, data, options, Ajax.ajax);
    }
    private on401(error: AjaxResponse): void {
        // 有免登录, 默认弹框, 再返回上个页面
        if (Envs.isStandaloneAndDirectAccess()) {
            let accountSigninScene = Storage.Account.session().get(Consts.ACCOUNT_SIGN_IN_SCENE); 
            const accountSignInUrl = Storage.Account.session().get(Consts.ACCOUNT_SIGN_IN_URL);
            const authFailTo = Utils.fromQueryString().authFailTo;
            // 优先免登录包含authFailTo,在使用session中的返回地址 即指定了登录失败页
            // 直接重定向到该页面, 使用replace防止无限循环
            if (authFailTo) {
                if(Utils.isBlank(accountSigninScene)){
                    accountSigninScene = '登录信息已经失效, 请重新登录后再进行操作.';
                }
                Alert.error(accountSigninScene).then(() => {
                    window.location.replace(authFailTo);
                });
            } else  if (accountSignInUrl) {
                if(Utils.isBlank(accountSigninScene)){
                    accountSigninScene = [
                        '登录/链接已经失效.',
                        '页面无法跳转,请重新登录/分享,如仍然有错误,请联系系统服务提供商咨询解决.'
                    ];
                }
                Alert.error(accountSigninScene).then(() => {
                    if (Utils.isHttpKey(accountSignInUrl)) {
						Storage.clear();
						window.location.replace(accountSignInUrl);
						return;
					} else {
						Storage.clear();
						this.getWidget()
							.getHelpers()
							.getRouter()
							.replaceRedirect(accountSignInUrl);
						return;
					}
                });
            }else {
                Alert.error([
                    '登录/链接已经失效.',
                    '页面无法跳转,请重新登录/分享,如仍然有错误,请联系系统服务提供商咨询解决.'
                ]).then(() => {
                    window.history.back();
                });
            }
        } else {
            console.log(
                '%c401 %cBreak ajax chain on 401 returned and redirect to sign page.',
                'color: chocolate',
                'color: normal'
            );
            let accountSigninScene = Storage.Account.session().get(Consts.ACCOUNT_SIGN_IN_SCENE); 
            if(Utils.isBlank(accountSigninScene)){
                accountSigninScene = '请先登录后再投保.';
            }
			const accountSignInUrl = Storage.Account.session().get(Consts.ACCOUNT_SIGN_IN_URL);
            if(Utils.isBlank(accountSignInUrl)){
                accountSigninScene = '没有权限获取数据.'; 
            }
            Alert.error(accountSigninScene).then(() => {
				if (accountSignInUrl) {
					if (Utils.isHttpKey(accountSignInUrl)) {
						Storage.clear();
						window.location.replace(accountSignInUrl);
						return;
					} else {
						Storage.clear();
						this.getWidget()
							.getHelpers()
							.getRouter()
							.replaceRedirect(accountSignInUrl);
						return;
					}
				}
				// const tenantCode =
				// 	Envs.findAccount().tenantCode || Storage.Account.session().get(Consts.ACCOUNT_SIGN_IN_FROM);
				Storage.clear();
                //跳转到接口权限错误页
                window.location.replace(Envs.getContextLocation("/mobile/no-power", { protocolHost: true }));
				// this.getWidget()
				// 	.getHelpers()
				// 	.getRouter()
				// 	.replaceRedirect(Envs.isDesk() ? PATH.DESK_NOT_FOUND : Utils.getMobileSigninPagePath(tenantCode));

			});
        }
    }
    private on403(error: AjaxResponse): void {
        // deal with 403, no access permitted exception
        Alert.error('您没有权限进行本操作.');
    }
    private on406(error: AjaxResponse): void {
        // deal with 406, validation exception
        const errors = (error.body.errors || [])
            .map((err: AjaxResponseErrorItem) => {
                const code = err.code;
                const description = Errors[code] || err.description;
                if (description) {
                    return description;
                } else {
                    console.warn(`Error[${code}] description definition missed.`);
                    return null;
                }
            })
            .filter((err: AjaxResponseErrorItem) => {
                // 如果后台没有传递描述, 前台也没有定义过, 则忽略掉.
                return err != null;
            });
        if (errors && errors.length !== 0) {
            // 如果有异常信息, 则显示
            Alert.error(errors as string[]);
        } else {
            // 没有匹配到任何异常描述
            console.warn(`Error occurred, but ignored since no description neither from server nor from client.`);
            Alert.error('系统异常, 请稍后再试.');
        }
    }
    private on413(error: AjaxResponse): void {
        // deal with 413, upload Image is larger than 10M
        Alert.error('上传文件尺寸过大, 请更换重试.');
    }
    private on4xx = (error: AjaxResponse): void => {
        if (error.status >= 400 && error.status < 500) {
            switch (error.status) {
                case 401:
                    this.on401(error);
                    break;
                case 403:
                    this.on403(error);
                    break;
                case 406:
                    this.on406(error);
                    break;
                case 413:
                    this.on413(error);
                    break;
                default:
                    // do nothing
                    break;
            }
        }
    };
    private on502(error: AjaxResponse): void {
        Alert.error('服务正在维护中, 请稍后再试.');
    }
    private on5xx = (error: AjaxResponse): void => {
        if (error.status >= 500) {
            switch (error.status) {
                case 502:
                    this.on502(error);
                    break;
                default:
                    // TODO 如何记录上报5xx异常?
                    Alert.error('系统异常, 请稍后再试.');
            }
        }
    };
}

/**
 * helpers
 */
class Helpers implements WidgetHelpers {
    private widget: Widget<any, any, any>;
    private router?: IWidgetRouterHelper;
    private ajax?: IWidgetAjaxHelper;

    constructor(widget: Widget<any, any, any>) {
        this.widget = widget;
    }
    /**
     * @return {Widget}
     */
    getWidget(): Widget<any, any, any> {
        return this.widget;
    }
    getRouter(): IWidgetRouterHelper {
        return this.router ? this.router : (this.router = new WidgetRouterHelper(this.getWidget()));
    }
    /**
     * @return {WidgetAjaxHelper}
     */
    getAjax(): IWidgetAjaxHelper {
        return this.ajax ? this.ajax : (this.ajax = new WidgetAjaxHelper(this.getWidget()));
    }
}

/**
 * 组件. 所有UI组件都应当继承自这里.
 * state已经被初始化为空对象
 */
abstract class Widget<Properties extends WidgetProps, State, InnerComponents> extends React.Component<Properties, State>
    implements WidgetHelped {
    protected C: InnerComponents;
    private helpers: WidgetHelpers;
    protected compName: string;

    constructor(props: any, context?: any) {
        super(props, context);
        // 注册成为运行态组件
        registerIntoRuntime(this);
        this.state = {} as State;
        this.helpers = new Helpers(this);
        this.C = this.initComponents();
        this.compName = this.constructor.name;
    }
    /**
     * 初始化内部使用的组件
     */
    protected abstract initComponents(): InnerComponents;
    protected getComponents(): InnerComponents {
        return this.C;
    }

    // 生命周期
    componentDidMount() { }
    shouldComponentUpdate(nextProps: any, nextState?: any, nextContext?: any) {
        return true;
    }
    componentDidUpdate(prevProps: any, prevState?: any, prevContext?: any) { }
    componentWillUnmount() {
        unregisterFromRuntime(this);
    }
    /**
     * @return {Helpers}
     */
    getHelpers(): WidgetHelpers {
        return this.helpers;
    }
    renderCSS(theme: StyledPropsTheme): string {
        if (this.props.css) {
            return this.props.css.call(this, this.props, theme);
        } else {
            return '';
        }
    }

    setComponentName(name: string) {
        this.compName = name;
    }

    getComponentName() {
        return this.compName;
    }
}

export const WidgetContext = React.createContext({});
Widget.contextType = WidgetContext;

export default Widget;
export { WidgetRouterHelper };
