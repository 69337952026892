// IMPORTANT 这里不能随便import, 会引起不必要的打包引入
import * as History from 'history';
import * as StyledFunctions from 'styled-components';

/** 二维码参数 */
export type QrcodeOptions = {
	minVersion?: number;
	maxVersion?: number;
	ecLevel?: string;
	size?: number;
	/** color */
	fill?: string;
	background?: string;
	radius?: number;
	quiet?: number;
	mode?: number;
	mSize?: number;
	mPosX?: number;
	mPosY?: number;
	fontname?: string;
	fontcolor?: string;
	label?: string;
	image?: string;
};

/** 二维码产生器 */
export interface QRCodeGeneratorFunc {
	generate(text: string, options: QrcodeOptions): Promise<void>;

	getImage(): string;
}

/** 二维码模式 */
export class modes {
	NORMAL: number = 0;
	DRAW_WITH_LABEL_STRIP: number = 1;
	DRAW_WITH_LABEL_BOX: number = 2;
	DRAW_WITH_IMAGE_STRIP: number = 3;
	DRAW_WITH_IMAGE_BOX: number = 4;
}

/** 二维码质量等级 */
export class ecLevel {
	LOW: string = 'L';
	MEDIUM: string = 'M';
	QUARTILE: string = 'Q';
	HIGH: string = 'H';
}

/** 二维码库 */
export type QRCodeGeneratorLib = {
	qrcode: QRCodeGeneratorFunc & any;
	modes: modes;
	ecLevel: ecLevel;
};

export type ImageCompressorOptions = {
	toWidth?: number;
	toHeight?: number;
	/** default png */
	mimeType?: 'image/png' | 'image/jpeg';
	mode: 'strict' | 'stretch';
	/** 0 - 1, default 1 */
	quality?: number;
	/** default false */
	grayScale?: boolean;
	/** default false */
	sepia?: boolean;
	/** default false */
	threshold?: number | boolean;
	/** default false */
	vReverse?: boolean;
	/** default false */
	hReverse?: boolean;
	/** default low */
	speed?: 'low' | 'high';
};

export enum ImageCompressStrategy {
	BRAIN_POWER = 'BRAIN_POWER',//智能的
	UNUSE = 'UNUSE',//不使用
	USE = 'USE',
}

export interface ImageCompressorLib {
	new(): ImageCompressorLib;

	run: (
		image: string,
		options: ImageCompressorOptions,
		callback: (compressedImage: string) => void
	) => void;
}

/** 标准省份/城市/区县联动结构 */
export type StandardChinaRegionsType = {
	readonly PROVINCES: CodeItem[];
	readonly CITIES: {
		readonly [propName: string]: CodeItem[];
		readonly [index: number]: CodeItem[];
	};
	readonly DISTRICTS: {
		readonly [propName: string]: CodeItem[];
		readonly [index: number]: CodeItem[];
	};
	getCityList: (provinceCode: string | number) => CodeItem[];
	getDistrictList: (cityCode: string | number) => CodeItem[];
};

// 数据模型 *********************************************
/** 代理模型 */
export type ProxiedModel = {
	$$emitter: any;
	/** 返回自己 */
	$$on: (prop: string, listener: ModelDataChangeEventListener) => any;
	/** 返回自己 */
	$$off: (prop: string, listener: ModelDataChangeEventListener) => any;
	$$createEvent: (
		model: any,
		prop: string,
		oldValue: any,
		newValue: any
	) => ModelDataChangedEvent;
	$$fireEvent: (prop: string, oldValue: any, newValue: any) => void;
	/** 被代理的对象 */
	$$model: any;
	/** 是否是被代理过的对象, 如果没有被代理过, 通常值是undefined. 被代理过, 值是true */
	$$proxied: boolean;
	[propName: string]: any;
};
/** 模型属性值变化事件 */
export type ModelDataChangedEvent = {
	model: any;
	prop: string;
	oldValue?: any;
	newValue?: any;
};
/** 模型属性值变化监听器 */
export type ModelDataChangeEventListener = (
	event: ModelDataChangedEvent
) => void;
/** 简单属性 */
export type ParsedIdSimple = {
	id: string;
	type: 'simple';
};
/** 索引属性 */
export type ParsedIdIndex = {
	id: string;
	type: 'index';
	index: number;
};
/** 表达式属性 */
export type ParsedIdExpression = {
	id: string;
	type: 'expression';
	exact: boolean;
	filter: FilterExpression | FilterExpressionSimple;
};
/** 简单表达式解析结果 */
export type FilterExpressionSimple = {
	prop: string;
	operator: '=' | '==' | '<>' | '!=' | '>' | '>=' | '<' | '<=' | '===' | '!==';
	value: string;
};
/** 表达式 */
export type FilterExpression = {
	type: 'and' | 'or';
	expressions: (FilterExpression | FilterExpressionSimple)[];
};
/** 过滤器规则参数 */
export type FilterRuleOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	rules: FilterRuleFunction[];
	caller: ModelWidgetInterface & WidgetHelped;
};
/** 过滤器规则函数 */
export type FilterRuleFunction = (
	caller: ModelWidgetInterface & WidgetHelped,
	options: FilterRuleOptions
) => boolean;
/** 复杂过滤器规则 */
export type ComplexFilterRule = {
	and?: string | string[] | (string | FilterRuleFunction)[] | FilterRuleFunction | FilterRuleFunction[];
	or?: string | string[] | (string | FilterRuleFunction)[] | FilterRuleFunction | FilterRuleFunction[];
};
/** 可过滤定义 */
export type Filterable = {
	on?:
	| string
	| string[]
	| FilterRuleFunction
	| FilterRuleFunction[]
	| ComplexFilterRule;
};
export type VisibleFilterable = { do?: boolean } & Filterable;
export type EditableFilterable = { do?: boolean } & Filterable;
/** 校验规则函数参数 */
export type CheckRuleOptions = {
	caller: ModelWidgetInterface & WidgetHelped;
	model: any;
	root: any;
	value: any;
	arrayHolder: any;
};
/** 预置校验规则函数参数 */
export type PredefinedCheckRuleOptions = {
	rule: CheckRule;
	params: any;
} & CheckRuleOptions;
/**
 * 自定义校验规则
 *
 * @return true表示通过, false表示没有通过校验
 */
export type CheckRuleFuncion = (options: CheckRuleOptions) => boolean | string;

/** 校验规则, 必要项 */
export type CheckRuleRequired = {
	do: PredefinedCheckRules.REQUIRED;
};

/** 校验规则, 正则表达式 */
export type CheckRuleRegexp = {
	do: PredefinedCheckRules.REGEXP;
	with:
	| {
		regexp: RegExp | CheckRuleRegexpPredefinedSet;
		contrary?: boolean;
	}
	| RegExp
	| CheckRuleRegexpPredefinedSet;
};
/** 校验规则, 日期区间 */
export type CheckRuleDateRange = {
	do: PredefinedCheckRules.DATE_RANGE;
	with: {
		/** 与当前日期进行比较, 使用startFrom/endWith的boolean值指定来表示与当前日期的关系 */
		now?: boolean;
		/** 与某个属性值进行比较. boolean值用于与now属性的结合 */
		startFrom?: string | boolean;
		/** 与某个属性值进行比较. boolean值用于与now属性的结合 */
		endWith?: string | boolean;
		/** 是否允许相等, 默认true */
		canEqual?: boolean;
		/** 指定用于比较的时间格式, 默认只有年月日, 时分秒都会被清除, 不参与比较 */
		format?: string;
		/** 指定被比较值的计算规则 */
		offset?: string;
	};
};
export type CheckRuleAgeRange = {
	do: PredefinedCheckRules.AGE_RANGE;
	with: string;
};
export type CheckRuleNumericRange = {
	do: PredefinedCheckRules.NUMERIC_RANGE;
	with: string;
};
/** 银行卡号校验 */
export type CheckRuleBankCardNumber = {
	do: PredefinedCheckRules.BANK_CARD_NUMBER;
	with?: {
		cardTypePropName?: string;
		bankCodePropName?: string;
		codeMapping?: { [propName: string]: string | number };
	};
};
export type CheckRuleGenderOnRelation = {
	do: PredefinedCheckRules.GENDER_ON_RELATION;
	with?: {
		relationPropName?: string;
	};
};
export type CheckRuleBmi = {
	do: PredefinedCheckRules.BMI;
	with: {
		range: string;
		heightPropName?: string;
		weightPropName?: string;
	};
};

/** 校验规则, 自定义函数 */
export type CheckRuleByFunction = {
	/** 执行的校验规则, 如果是string, 则执行预置规则 */
	do: CheckRuleFuncion;
};
export type CheckRuleMessage = { msg?: string };
/** 校验规则 */
export type CheckRule = (
	| CheckRuleRequired
	| CheckRuleRegexp
	| CheckRuleDateRange
	| CheckRuleAgeRange
	| CheckRuleNumericRange
	| CheckRuleBankCardNumber
	| CheckRuleGenderOnRelation
	| CheckRuleBmi
	| CheckRuleRelationWithPolicyHolder
	| CheckRuleRelationWithPolicyHolderDuplication
	| CheckRuleRelationWithMainInsured
	| CheckRuleRelationWithMainInsuredDuplication
	| CheckRuleRelationWithInsured
	| CheckRuleRelationWithInsuredDuplication
	| CheckRuleRelationWithPHAndMI
	| CheckRuleIdNumber
	| CheckRuleIdNumberMatch
	| CheckRuleChinaPassport
	| CheckRulePermanentResidentPermit
	| CheckRulePostCode
	| CheckRuleByFunction) &
	CheckRuleMessage &
	Filterable;
// 数据模型 *********************************************


/** 预定义的校验规则名称 */
export enum PredefinedCheckRules {
	REQUIRED = 'required',
	REGEXP = 'regexp',
	DATE_RANGE = 'dateRange',
	BANK_CARD_NUMBER = 'bankCardNumber',
	GENDER_ON_RELATION = 'genderOnRelation',
	AGE_RANGE = 'ageRange',
	NUMERIC_RANGE = 'numericRange',
	BMI = 'bmi',
	RELATION_WITH_POLICY_HOLDER = 'relationWithPolicyHolder',
	RELATION_WITH_POLICY_HOLDER_DUPLICATION = 'relationWithPolicyHolderDuplication',
	RELATION_WITH_MAIN_INSURED = 'relationWithMainInsured',
	RELATION_WITH_MAIN_INSURED_DUPLICATION = 'relationWithMainInsuredDuplication',
	RELATION_WITH_PH_AND_MI = 'relationWithPHAndMI',
	RELATION_WITH_INSURED = 'relationWithInsured',
	RELATION_WITH_INSURED_DUPLICATION = 'relationWithInsuredDuplication',
	ID_NUMBER = 'idNumber',
	ID_NUMBER_MATCH = 'idNumberMatch',
	PERMANENT_RESIDENT_PERMIT = 'permanentResidentPermit',
	CHINA_PASSPORT = 'chinaPassport',
	POST_CODE = 'postcode'
}

/** 预置正则校验规则名称, 参考predefined-regexp.tsx文件 */
export enum CheckRuleRegexpPredefinedSet {
	EMAIL = 'EMAIL',
	MOBILE = 'MOBILE',
	BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
	MILITARY_CERTIFICATE = 'MILITARY_CERTIFICATE',//军官证校验
	SOLDIER_CERTIFICATE = 'SOLDIER_CERTIFICATE',//士兵证校验
	IDENTITY_CARD = 'IDENTITY_CARD',
	POSTCODE = 'POSTCODE',
	CHINA_PASSPORT = 'CHINA_PASSPORT',
	TW_ID_CARD = 'TW_ID_CARD',
	TW_MAINLAND = 'TW_MAINLAND',
	HK_MAINLAND = 'HK_MAINLAND',
	DATE = 'DATE',
	SLOGAN_LENGTH = 'SLOGAN_LENGTH',
	HEIGHT_IN_CM = 'HEIGHT_IN_CM',
	HEIGHT_IN_CM_FRACTION = 'HEIGHT_IN_CM_FRACTION',
	HEIGHT_IN_CM_FRACTION2 = 'HEIGHT_IN_CM_FRACTION2',
	WEIGHT_IN_CATTY = 'WEIGHT_IN_CATTY',
	WEIGHT_IN_KG = 'WEIGHT_IN_KG',
	WEIGHT_IN_KG_FRACTION = 'WEIGHT_IN_KG_FRACTION',
	WEIGHT_IN_KG_FRACTION2 = 'WEIGHT_IN_KG_FRACTION2',
	ENGLISH_NAME = 'ENGLISH_NAME',
	DIGITS = 'DIGITS',
	FLOAT = 'FLOAT',
	HK_IDENTITY_CARD = 'HK_IDENTITY_CARD',
	PASSPORT = 'PASSPORT',
	TW_IDENTITY_CARD = 'TW_IDENTITY_CARD'
}


export enum RelationTypeWith {
	SELF = '1', // 本人
	SPOUSE = '2', // 配偶
	CHILD = '3', // 子女
	PARENTS = '4', // 父母
	GRAND_PARENT = '5', // (外)祖父母
	GRAND_CHILDREN = '6', // 孙辈
	FOSTER_PARENT = '7', // 养父母
	EMPLOY = '8', // 雇佣
	HEIR_AT_LAW = '9', // 法定继承人
	SIBLING = '10', // 兄弟姐妹
	WIFE = '11', // 妻子
	HUSBAND = '12', // 丈夫
	SON = '13', // 儿子
	DAUGHTER = '14', //女儿
	FATHER = '15', // 父亲
	MOTHER = '16', // 母亲
	GRAND_SON_PARTRILOCAL = '17', // 孙子
	GRAND_DAUGHTER_PARTRILOCAL = '18', // 孙女
	GRAND_SON_MATRIARCHAL = '19', // 外孙
	GRAND_DAUGHTER_MATRIARCHAL = '20', // 外孙女
	GRAND_FATHER_PARTRILOCAL = '21', // 祖父
	GRAND_MOTHER_PARTRILOCAL = '22', // 祖母
	GRAND_FATHER_MATRIARCHAL = '23', // 外祖父
	GRAND_MOTHER_MATRIARCHAL = '24', // 外祖母
	UNCLE = '25', // 叔伯
	KINSFOLK = '26', // 亲属
	GENDER_PARTRILOCAL = '31',//孙子女
	GENDER_MATRIARCHAL = '32',//外孙子女
    CHILDSPOUSE = '33',//子女配偶
	ELDER_BROTHER = '50', // 哥哥
	YOUNGER_BROTHER = '51', // 弟弟
	ELDER_SISTER = '52', // 姐姐
	YOUNGER_SISTER = '53', // 妹妹
    SON_1 = '100',//大儿子
	SON_99 = '199', //小儿子
	DAUGHTER_1 = '200',//大女儿
	DAUGHTER_99 = '299',//小女儿
    GUARDIAN = '992',//监护人
    WORD = '993',//被监护人
	OTHER = '999' // 其他
}

export enum RelationTypeWithCheckType {
	AGE = 1,
	GENDER = 2
}

export type CheckRuleRelationWithPolicyHolder = {
	do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName: string;
		type: RelationTypeWithCheckType;
	};
};
export type CheckRuleRelationWithPolicyHolderDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
export type CheckRuleRelationWithMainInsured = {
	do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName: string;
		type: RelationTypeWithCheckType;
	};
};
/** 用于附加被保人与投保人关系栏位校验 */
export type CheckRuleRelationWithMainInsuredDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
/** 用于受益人与被保人之间的校验 */
export type CheckRuleRelationWithInsured = {
	do: PredefinedCheckRules.RELATION_WITH_INSURED;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
		relationPropName?: string;
		insuredPropName?: string;
		type: RelationTypeWithCheckType;
	};
};
/** 用于受益人与被保人之间的校验 */
export type CheckRuleRelationWithInsuredDuplication = {
	do: PredefinedCheckRules.RELATION_WITH_INSURED_DUPLICATION;
	with: {
		/** 翻译当前的关系值成为标准关系值, 如果不需要翻译则不需要指定 */
		relationTransformer?: (value: any) => RelationTypeWith;
	};
};
/** 用于附加被保人与投保人关系栏位校验 */
export type CheckRuleRelationWithPHAndMI = {
	do: PredefinedCheckRules.RELATION_WITH_PH_AND_MI;
	with: {
		/** 附加被保人与主保险人关系栏位 */
		relationPropName: string;
	};
};
export type CheckRuleIdNumber = {
	do: PredefinedCheckRules.ID_NUMBER;
	// idType字段名
	with: string;
};

export enum IdNumberMatchCheckType {
	DATE_OF_BIRTH = 1,
	GENDER = 2
}

export type CheckRuleIdNumberMatch = {
	do: PredefinedCheckRules.ID_NUMBER_MATCH;
	with: {
		type: IdNumberMatchCheckType;
		// 属性前缀
		prefix?: string;
	};
};
export type CheckRuleChinaPassport = {
	do: PredefinedCheckRules.CHINA_PASSPORT;
	// idType字段名
	with: string;
};
export type CheckRulePermanentResidentPermit = {
	do: PredefinedCheckRules.PERMANENT_RESIDENT_PERMIT;
	// 对象id
	with: string;
};
export type CheckRulePostCode = {
	do: PredefinedCheckRules.POST_CODE;
	// 对象id
	with: string;
};

// AJAX通讯 *********************************************
/** 请求HEADERS */
export type AjaxHeaders = {
	'Content-Type'?: string | boolean;
	[propName: string]: string | undefined | null | boolean;
};
/** 请求返回错误项目 */
export type AjaxResponseErrorItem = {
	code: string;
	description?: string | null;
};
/** 请求返回主体 */
export type AjaxResponseBody = {
	returnCode?: string | null;
	body?: object;
	errors?: AjaxResponseErrorItem[];
};
/** 标准请求返回 */
export type AjaxResponse = {
	headers?: AjaxHeaders;
	body?: AjaxResponseBody | any | null;
	status: number;
	statusText: string;
	error?: Error;
};

/** 请求方式 */
export enum AjaxMethod {
	GET = 'get',
	POST = 'post'
}

/** 请求参数 */
export type AjaxOptions = {
	method?: AjaxMethod;
	headers?: AjaxHeaders;
	credentials?: string;
	mode?: string;
	redirect?: string;
	cache?: string;

	dataType?: string;
	ignoreAuth?: boolean;
};
/** 请求响应处理器 */
export type AjaxHandler = (response: AjaxResponse) => void;
// AJAX通讯 *********************************************

// 账户 *********************************************
export type EnvAccount = {
	tenantId?: string | number | null;
	tenantCode?: string | null;
	organizationId?: string | number | null;
	type?: string | number | null;
	accountName?: string | null;
	nickname?: string | null;
	avatar?: string;
	menus?: string | null;
	salesAreaCode?: string | null;
	mobile?: string;
	email?: string;
	tenantName?: string | undefined;
	agentLicenseNo?: string;
	organizationName?: string;
	accountId?: string | number | null;
	idType?: string | null;
	idNo?: string | null;
	attachType?: string | null;
};
// 账户 *********************************************

// 应用 *********************************************
export interface Application {
	getTheme(): StyledPropsTheme;

	changeTheme(theme: string): void;
	
	changeThemeColor(themeColor?: string): void;

	getThemeName(): string;

	setScrollDisabled(shown?: boolean): void;

	isScrollDisabled(): boolean;

	getHistory(): History.History;
}

// 应用 *********************************************

// STYLED-COMPONENTS *********************************************
/**
 * 强类型限制, 增加属性必须加上类型限制以及readonly
 * 如果没有显式定义类型, 则其他文件引用后无法提示语法
 */
export type StyledPropsTheme = {
	/** 桌面HTML水平外边距 */
	readonly DESK_HTML_HORIZONTAL_MARGIN: number;
	/** 桌面滚动条宽度 */
	readonly DESK_HTML_SCROLL_WIDTH: number;
	/** 桌面按钮最小宽度 */
	readonly DESK_BTN_MIN_WIDTH: number;
	/** 桌面格子内部标签的宽度 */
	readonly DESK_CELL_INNER_LABEL_WIDTH: number;
	/** 错误提示淡出动画时间长度 */
	readonly ERROR_REMINDER_FADE_OUT_DURATION: string;
	/** 桌面对话框横向内边距 */
	readonly DESK_DIALOG_HORIZONTAL_PADDING: number;
	/** 桌面页眉高度 */
	readonly DESK_PAGE_HEADER_HEIGHT: number;
	/** 手机HTML水平外边距 */
	readonly MOBILE_HTML_HORIZONTAL_MARGIN: number;
	/** 手机页眉高度 */
	readonly MOBILE_PAGE_HEADER_HEIGHT: number;
	/** 手机页脚高度 */
	readonly MOBILE_PAGE_FOOTER_HEIGHT: number;
	/** 手机菜单按钮高度 */
	readonly MOBILE_MENU_ITEM_HEIGHT: number;
	/** 手机格子内部标签的宽度 */
	readonly MOBILE_CELL_INNER_LABEL_WIDTH: string;
	/** 手机面板上部外边距 */
	readonly MOBILE_PANEL_MARGIN: number;
	/** 页头层 */
	readonly PAGE_HEADER_Z_INDEX: number;
	/** 页脚层 */
	readonly PAGE_FOOTER_Z_INDEX: number;
	/** HTML字体 */
	readonly FONT_SIZE_HTML: number;
	/** 超大字体 */
	readonly FONT_SIZE_SUPER: number;
	/** 大字体4 */
	readonly FONT_SIZE_L4: number;
	/** 大字体3 */
	readonly FONT_SIZE_L3: number;
	/** 大字体2 */
	readonly FONT_SIZE_L2: number;
	/** 大字体 */
	readonly FONT_SIZE_LARGE: number;
	/** 标准字体 */
	readonly FONT_SIZE: number;
	/** 小字体 */
	readonly FONT_SIZE_SMALL: number;
	/** 标准字体 */
	readonly FONT_FAMILY: string;
	/** 标准字体称重 */
	readonly FONT_WEIGHT: number;
	/** 轻量加粗字体称重 */
	readonly FONT_WEIGHT_BOLD_LIGHT: number;
	/** 标准加粗字体称重 */
	readonly FONT_WEIGHT_BOLD: number;
	readonly COLOR_FONT_LINK: string;
	readonly COLOR_FONT_THREE: string;
	/** 标准字体色 */
	readonly FONT_COLOR: string;
	/** 淡化字体色 + 1 */
	readonly FONT_COLOR_LIGHT: string;
	/** 淡化字体色 + 2 */
	readonly FONT_COLOR_LIGHT2: string;
	/** 标准背景色 */
	readonly COLOR_BACKGROUND: string;
	/** 面板标准背景色 */
	readonly COLOR_BACKGROUND_PANEL: string;
	/** 页面导航栏背景色 */
	readonly COLOR_BACKGROUND_PAGE_HEADER: string;
	/** 失效色 */
	readonly COLOR_DISABLED: string;
	/** 标准色 */
	readonly COLOR_PRIMARY: string;
	/** 标准色75%透明 */
	readonly COLOR_PRIMARY_LIGHT_O75: string;
	/** 标准色10%透明 */
	readonly COLOR_PRIMARY_LIGHT_010: string;
	/** 标准色淡10% */
	readonly COLOR_PRIMARY_LIGHT: string;
	/** 反转色 */
	readonly COLOR_REVERSED: string;
	/** 放弃色 */
	readonly COLOR_WAIVE: string;
	/** 放弃色淡10% */
	readonly COLOR_WAIVE_LIGHT: string;
	/** 消息色 */
	readonly COLOR_INFO: string;
	/** 危险色 */
	readonly COLOR_DANGER: string;
	/** 危险色淡10% */
	readonly COLOR_DANGER_LIGHT: string;
	/** 错误色 */
	readonly COLOR_ERROR: string;
	/** 成功色 */
	readonly COLOR_SUCCESS: string;
	/** 询问色 */
	readonly COLOR_QUESTION: string;
	/** 警告色 */
	readonly COLOR_WARN: string;
	/** 价格色 */
	readonly COLOR_PRICE: string;
	/** 标准边距, 适用于上下左右 */
	readonly MARGIN: number;
	/** 下方阴影 */
	readonly SHADOW_BOTTOM: string;
	/** 边框色 */
	readonly BORDER_COLOR: string;
	/** 轻度淡化边框色 */
	readonly BORDER_COLOR_MINOR_LIGHT: string;
	/** 淡化边框色 */
	readonly BORDER_COLOR_LIGHT: string;
	/** 边框宽度 */
	readonly BORDER_WIDTH: number;
	/** 边框样式 */
	readonly BORDER_STYLE: string;
	/** 边框圆角 */
	readonly BORDER_RADIUS: number;
	/** 按钮圆角 */
	readonly BUTTON_BORDER_RADIUS: number;
	/** 选择框圆角 */
	readonly CHECK_BORDER_RADIUS: number;
	/** 边框 */
	readonly BORDER: string;
	/** 轻度淡化边框 */
	readonly BORDER_MINOR_LIGHT: string;
	/** 淡化边框 */
	readonly BORDER_LIGHT: string;
	/** 弹出层遮罩色 */
	readonly BACKDROP_COLOR: string;
	/** 对话框层 */
	readonly DIALOG_Z_INDEX: number;
	/** 对话框阴影 */
	readonly DIALOG_SHADOW: string;
	/** 底部弹出dialog的高度 */
	readonly DOWN_DIALOG_HEIGHT: number;
	/** 最大化弹出dialog的高度 */
	readonly MAX_DIALOG_HEIGHT: number;
	/** 警示框层 */
	readonly ALERT_Z_INDEX: number;
	/** 警示框阴影 */
	readonly ALERT_SHADOW: string;
	/** 警示框HINT背景色 */
	readonly ALERT_HINT_BACKGROUND_COLOR: string;
	/** 警示框HINT图标色 */
	readonly ALERT_HINT_ICON_COLOR: string;
	/** 警示框弧度*/
	readonly ALERT_BORDER_RADIUS: string;
	/** 行高 */
	readonly ROW_HEIGHT: number;
	/** 中等行高 */
	readonly MIDDLE_HEIGHT: number;
	/** 矮的行高 */
	readonly SHORT_HEIGHT: number;
	/** 图标宽度 */
	readonly ICON_WIDTH: number;
	/** 输入框上下内边距 */
	readonly INPUT_VERTICAL_PADDING: number;
	/** 输入框左右内边距 */
	readonly INPUT_HORIZONTAL_PADDING: number;
	/** 输入框高度 */
	readonly INPUT_HEIGHT: number;
	/** 输入框文字行高 */
	readonly INPUT_LINE_HEIGHT: number;
	/** 输入框上下外边距 */
	readonly INPUT_VERTICAL_MARGIN: number;
	/** 输入框背景色 */
	readonly INPUT_BACKGRAOUND_COLOR: string;
	/** 输入框获取焦点后边框色 */
	readonly INPUT_FOCUS_BORDER_COLOR: string;
	/** 日期输入框三角符号颜色 */
	readonly DATE_CARET_COLOR: string;
	/** 日期输入框三角符号尺寸 */
	readonly DATE_CARET_SIZE: number;
	/** 下拉框三角符号颜色 */
	readonly SELECT_CARET_COLOR: string;
	/** 下拉框三角符号尺寸 */
	readonly SELECT_CARET_SIZE: number;
	/** 下拉框三角符号离右边距离 */
	readonly SELECT_CARET_RIGHT: number;
	/** 按钮上下内边距 */
	readonly BTN_VERTICAL_PADDING: number;
	/** 按钮左右内边距 */
	readonly BTN_HORIZONTAL_PADDING: number;
	/** 按钮文字行高 */
	readonly BTN_LINE_HEIGHT: number;
	/** 按钮高度 */
	readonly BTN_HEIGHT: number;
	/** 按钮组内部按钮间距 */
	readonly BTN_GROUP_GAP: number;
	/** 按钮组内部按钮间隔色 */
	readonly BTN_GROUP_GAP_COLOR: string;
	/** 开关按钮上下内边距 */
	readonly TOGGLE_VERTICAL_PADDING: number;
	/** 开关按钮高度 */
	readonly TOGGLE_HEIGHT: number;
	/** 开关按钮宽度 */
	readonly TOGGLE_WIDTH: number;
	/** 开关按钮滑块宽度 */
	readonly TOGGLE_SLIDER_WIDTH: number;
	/** 开关按钮边框色 */
	readonly TOGGLE_BORDER_COLOR: string;
	/** 开关按钮背景色 */
	readonly TOGGLE_BACKGROUND_COLOR: string;
	/** 开关按钮滑块色 */
	readonly TOGGLE_SLIDER_COLOR: string;
	/** 开关按钮true边框色 */
	readonly TOGGLE_TRUE_BORDER_COLOR: string;
	/** 开关按钮true背景色 */
	readonly TOGGLE_TRUE_BACKGROUND_COLOR: string;
	/** 开关按钮true滑块色 */
	readonly TOGGLE_TRUE_SLIDER_COLOR: string;
	/** 勾选框右边外边距 */
	readonly CHECKBOX_MARGIN_RIGHT: number;
	/** markdown正文行高 */
	readonly MARKDOWN_BODY_LINE_HEIGHT: number;
	/** markdown正文文字尺寸 */
	readonly MARKDOWN_BODY_FONT_SIZE: number;
	/** 表格双数行背景色 */
	readonly SPREAD_EVEN_BG_COLOR: string;
	/** 表格行Hover背景色 */
	readonly SPREAD_HOVER_BG_COLOR: string;
	/** 面板内边距 */
	readonly PANEL_PADDING: number;
	/** 面板背景色 */
	readonly PANEL_BACKGROUND_COLOR: string;
	/** 面板格子横向间距 */
	readonly PANEL_CELL_HORIZONTAL_GAP: number;
	/** 面板格子纵向间距 */
	readonly PANEL_CELL_VERTICAL_GAP: number;
	/** 面板头高度 */
	readonly PANEL_HEADER_HEIGHT: number;
	/** 面板格子高度 */
	readonly PANEL_CELL_HEIGHT: number;
	/** 面板格子横向间距 */
	readonly CONTAINER_CELL_HORIZONTAL_GAP: number;
	/** 面板格子纵向间距 */
	readonly CONTAINER_CELL_VERTICAL_GAP: number;
	/** 格子内部横向间距 */
	readonly CELL_INNER_HORIZONTAL_GAP: number;
	/** 格子内部纵向间距 */
	readonly CELL_INNER_VERTICAL_GAP: number;
	/** 格子内部底边间隔, 只在Labelling是生效 */
	readonly CELL_BOTTOM_BORDER_GAP: number;

	/** 网销字体 */
	readonly MALL_FONT_FAMILY: string;
	/** 网销链接字体 */
	readonly MALL_LINK_COLOR: string;
	/** 网销标准色 */
	readonly MALL_STANDARD_COLOR: string;
	/** 网销辅助色 */
	readonly MALL_ASSIST_COLOR: string;
	/** 网销一级字体色 */
	readonly MALL_FIRST_FONT_COLOR: string;
	/** 网销二级字体色 */
	readonly MALL_SECOND_FONT_COLOR: string;
	/** 网销三级字体色 */
	readonly MALL_THIRD_FONT_COLOR: string;
	/** 网销背景色 */
	readonly MALL_BACKGROUD_COLOR: string;
	/** 网销PANEL色 */
	readonly MALL_PANEL_COLOR: string;
	/** 网销边框-分割线色 */
	readonly MALL_BORDER_COLOR: string;
};

/** 可自定义CSS属性 */
export interface StyledPropsCss {
	/** CSS */
	css?: (props: any, theme: StyledPropsTheme) => string;
}

/** 主题属性 */
export interface StyledProps extends StyledPropsCss {
	theme: StyledPropsTheme;
}

/** 全局样式属性 */
export type GlobalStyledProps = {
	scrollDisabled?: boolean;
};

export type StyledDIV = StyledFunctions.StyledComponent<'div', any, {}, never>;
export type StyledSPAN = StyledFunctions.StyledComponent<'span',
	any,
	{},
	never>;
export type StyledBUTTON = StyledFunctions.StyledComponent<'a', any, {}, never>;
export type StyledINPUT = StyledFunctions.StyledComponent<'input',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledSELECT = StyledFunctions.StyledComponent<'select',
	any,
	{ readOnly?: boolean },
	never>;
export type StyledI = StyledFunctions.StyledComponent<'i', any, {}, never>;
// STYLED-COMPONENTS *********************************************

// 基本组件属性 *********************************************
/** font-awesome图标属性 */
export interface FaIconProps extends StyledPropsCss {
	block?: boolean;
	color?: string;
	spin?: boolean;
	icon: string;
	className?: string;
	onClick?: (evt?: MouseEvent) => void;
	onTouchStart?: (evt?: TouchEvent) => void;
	onTouchEnd?: (evt?: TouchEvent) => void;
}

/** 输入框属性 */
export interface InputProps extends StyledPropsCss {
	type: string;
	placeholder?: string;
	disabled?: boolean;
	onFocus?: (evt?: FocusEvent) => void;
	onBlur?: (evt?: FocusEvent) => void;
}

/** 可点击的组件 */
export interface Clickable {
	onClick?: (
		invoker: ModelWidgetInterface & WidgetHelped,
		evt: MouseEvent
	) => void;
}

export enum ButtonType {
	PRIMARY = 'primary',
	WAIVE = 'waive',
	DANGER = 'danger'
}

export enum CheckValueType {
	BOOLEAN = 1,
	YES_NO = 2
}

/** 按钮属性 */
export interface ButtonProps extends StyledPropsCss, Clickable {
	type?: ButtonType;
	disabled?: boolean;
}

// 基本组件属性 *********************************************

// 组件 ********************************************
/** 组件属性 */
export interface IWidgetRouterHelper {
	forward(): this;

	back(): this;

	go(steps: number): this;

	pushRedirect(to: string): void;

	pushProtogeneRedirect(to: string): void;

	replaceRedirect(to: string): void;

	//非分享，网销产品从产品详情跳转至电投页时使用
	replaceProtogeneRedirect(to: string): void;

	gluttonReplace(to: string): Promise<void>;

	gluttonAssign(to: string): Promise<void>;

	gluttonHistoryGo(index: number): Promise<void>;

	gluttonHistoryBack(): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowReplace(to: string): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowAssign(to: string): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowHistoryGo(index: number): Promise<void>;

	/**
	 * @deprecated never used
	 */
	windowHistoryBack(): Promise<void>;
}

export type OPTIONS = AjaxOptions & {
	ignore4xx?: boolean;
	ignore5xx?: boolean;
	ignoreFail?: boolean;
	loading?: boolean;
};

export interface IWidgetAjaxHelper {
	post(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse>;

	get(url: string, data?: any, options?: OPTIONS): Promise<AjaxResponse>;

	all(promises: Promise<any>[]): Promise<any[]>;
}

export interface WidgetHelpers {
	getRouter(): IWidgetRouterHelper;

	getAjax(): IWidgetAjaxHelper;
}

export interface WidgetHelped {
	getHelpers(): WidgetHelpers;
}

export type WidgetProps = StyledPropsCss & {
	theme?: StyledPropsTheme;
};
export type ReadonlyProps = {
	/** 是否只读, 没有设置默认可操作 */
	readonly?: boolean | string | string[] | Filterable | Filterable[];
};
export type InitProcessor = (options: InitProcessEvent) => void;
export type InitProcessEvent = {
	invoker: ModelWidgetInterface & WidgetHelped;
	[propName: string]: any;
}


export type ForceListener = (options: ForceListenEvent) => void;
export type ForceListenEvent = {
	invoker: ModelWidgetInterface & WidgetHelped;
	model: any;
	root: any;
	arrayHolder: any;
	value: any;
	oldValue: any;
};

/** 模型组件属性 */
export interface ModelWidgetInterface {
	getPropName(): string;

	getModel(): any;

	getRootModel(): any;

	getArrayHolder(): any;
}

export type ModelWidgetProps = {
	/** 属性名, 别名. 没有propName的时候生效 */
	id?: string;
	/** 属性名 */
	propName?: string;
	/** 模型 */
	model?: object;
	/** 根模型 */
	root?: object;
	/** 如果本组件是数组元素, 则会有数组持有者 */
	arrayHolder?: object;
	/** 是否显示 */
	visible?: false | string | string[] | Filterable | Filterable[];
	/** 监听器, 在值变化的时候触发 */
	listen?: ForceListener;
	/** 组件初始处理器 */
	process?: InitProcessor;
} & WidgetProps;
/** 可校验组件属性 */
export type CheckableWidgetProps = {
	/** 校验规则 */
	check?: boolean | CheckRule | CheckRule[];
	checkStages?: string | string[];
	/** 校验规则前置条件, 不满足则不触发校验规则 */
	triggerCheckOn?: string | string[] | Filterable | Filterable[];
} & ModelWidgetProps;
/** 可校验组件状态 */
export type CheckableWidgetState = {
	/** 是否因为某种原因导致校验失败, 原因无论 */
	checkFailOnWhatever?: boolean;
	/** 校验失败原因信息 */
	checkFailMsg?: string;
};
/** 可校验组件校验结果 */
export type CheckableWidgetCheckResult = {
	/** 未通过的校验规则 */
	rule?: CheckRule;
	/** 什么组件发生校验失败 */
	where: any;
};
/** 码表元素 */
export type CodeItem = {
	/** 显示标签 */
	l: string;
	/** 码值 */
	v: string | number | boolean;
	/** 上级值 */
	p?: string | number;
	/** 数值值 */
	num?: number;
	/** 单证上显示值 */
	n?: string | undefined;
	/** 子标签1*/
	cl1?: string | undefined;
	/** 子标签2*/
	cl2?: string | undefined;

};
/** 码表组件属性 */
export type CodesWidgetProps = {
	clearable?: boolean;
	codes: CodeItem[];
};
export type CodesFilter =
	| {
		filter: (
			codes: any,
			invoker: ModelWidgetInterface & WidgetHelped
		) => CodeItem[];
		on: string;
	}
	| ((codes: any, invoker: ModelWidgetInterface & WidgetHelped) => CodeItem[]);

/** 码表组件 */
export interface CodesWidget {
	isClearable(): boolean;

	getCodes(): CodeItem[];
}

/** 错误提醒组件属性 */
export type ErrorReminderProps = {
	/** 提醒内容 */
	msg: string;
};
export type LabelDecoratorOptions = {
	root: any;
	model: any;
	arrayHolder: any;
	value: any;
	invoker: ModelWidgetInterface & WidgetHelped;
	decorated: any;
};
export type LabelDecorator = (options: LabelDecoratorOptions) => any;
export type LabelCss = (props: any, theme: StyledPropsTheme) => string;

/** 页眉内部组件 */
export type PageHeaderComponents = {
	Box: StyledDIV;
};
/** 页面主体内部组件 */
export type PageBodyComponents = {
	Box: StyledDIV;
};
/** 页脚内部组件 */
export type PageFooterComponents = {
	Box: StyledDIV;
};
/** 页面内部组件 */
export type PageComponents = {
	Box: StyledDIV;
};

// 桌面组件 *******************************************************
/** 桌面页眉内部组件 */
export type DeskPageHeaderComponents = {
	Logo: StyledI;
	LogoText: StyledDIV;
} & PageHeaderComponents;
/** 桌面页眉菜单集内部组件 */
export type DeskPageHeaderMenusComponents = {
	Box: StyledDIV;
};
/** 桌面页眉标准菜单内部组件 */
export type DeskPageHeaderMenuComponents = {
	Box: StyledDIV;
	SubMenus: StyledDIV;
	SubMenu: StyledDIV;
	SubMenus2: StyledDIV;
	SubMenu2: StyledDIV;
};
/** 桌面页眉右菜单内部组件 */
export type DeskPageHeaderRightMenuComponents = {
	Box: StyledDIV;
	AccountText: StyledDIV;
	SignOut: StyledDIV;
};
/** 桌面页面主体属性 */
export type DeskPageBodyProps = {
	home?: boolean | null;
	where?: string | string[] | null;
};
/** 桌面页面主体内部组件 */
export type DeskPageBodyComponents = {
	LOC: StyledDIV;
	Home: StyledSPAN;
} & PageBodyComponents;
/** 桌面404页面主体内部组件 */
export type DeskNotFoundBodyComponents = {
	ICONS: StyledDIV;
	ICON: StyledSPAN;
	MSG: StyledDIV;
} & DeskPageBodyComponents;

// desk container
/** Flex容器对齐属性枚举 */
export enum FlexAlignment {
	START = 'start',
	END = 'end',
	CENTER = 'center',
	BETWEEN = 'between',
	AOURND = 'around',
	EVENLY = 'evenly'
}

/** Flex容器属性 */
export type FlexContainerProps = {
	align?: FlexAlignment;
} & ModelWidgetProps;
/** 网格容器属性 */
export type GridContainerProps = {
	/**
	 * 网格布局列数量, 如果定义了模板则不会生效.
	 * 生效时将容器按照列数量均分
	 */
	gridColumns?: number;
	/**
	 * 网格布局模板, 最优先生效
	 */
	gridTemplates?: string;
} & ModelWidgetProps;
/** 网格单元格属性 */
export type GridCellProps = {
	row?: number | string | null;
	rowSpan?: number | string | null;
	column?: number | string | null;
	columnSpan?: number | string | null;
};
/** 容器属性 */
export type ContainerProps = {
	layout?: object;
} & GridContainerProps;
/** 容器内部组件 */
export type ContainerComponents = {
	Box: StyledDIV;
};
/** 数组容器属性 */
export type ArrayContainerProps = FlexContainerProps;
/** 数组容器内部组件 */
export type ArrayContainerComponents = { Box: StyledDIV };
/** 数组容器行集合属性 */
export type ArrayContainerRowsProps = {
	nodata: ArrayContainerNoDataProps;
	sort?: (a: any, b: any) => number;
} & ModelWidgetProps;
/** 数组容器行属性 */
export type ArrayContainerRowProps = { index: number } & FlexContainerProps;
/** 数组容器行内部组件 */
export type ArrayContainerRowComponents = { Box: StyledDIV };
/** 数组容器无数据属性 */
export type ArrayContainerNoDataProps = {
	/** 显示标签 */
	label?: string | false;
} & ModelWidgetProps &
	FlexContainerProps;
export type ArrayContainerNoDataComponents = { Box: StyledDIV };
/** 面板属性 */
export type DeskPanelProps = {
	collapsible?: boolean;
	expanded?: boolean;
} & ModelWidgetProps;
/** 面板状态 */
export type DeskPanelState = { expanded?: boolean };
/** 面板内部组件 */
export type DeskPanelComponents = { Box: StyledDIV };
/** 面板头内部组件 */
export type DeskPanelHeaderComponents = { Box: StyledDIV };
/** 面板头标题内部组件 */
export type DeskPanelHeaderTitleComponents = { Box: StyledDIV };
/** 面板头折叠/展开图标属性 */
export type DeskPanelHeaderCollapsibleIconProps = {
	expanded: boolean;
	onClick: () => void;
} & WidgetProps;
/** 面板头折叠/展开图标内部组件 */
export type DeskPanelHeaderCollapsibleIconComponents = {
	Box: any;
};
/** 面板主体属性 */
export type DeskPanelBodyProps = {} & GridContainerProps;
/** 面板主体内部组件 */
export type DeskPanelBodyComponents = { Box: StyledDIV };
/** 面板主体单元格属性 */
export type DeskPanelBodyCellProps = {} & GridCellProps & FlexContainerProps;
/** 面板主体单元格内部组件 */
export type DeskPanelBodyCellComponents = { Box: StyledDIV };
/** 面板脚属性 */
export type DeskPanelFooterProps = {} & FlexContainerProps;
/** 面板脚内部组件 */
export type DeskPanelFooterComponents = { Box: StyledDIV };
/** 可以做为标签化组件父组件的面板主体单元格组件属性 */
export type DeskPanelLabelledBodyCellProps = {
	/**
	 * 标签文本
	 */
	label?: string;
	/**
	 * 标签宽度, 百分比数字
	 */
	labelWidth?: number;
} & DeskPanelBodyCellProps;
/** 可以做为标签化组件父组件的面板主体单元格组件状态 */
export type DeskPanelLabelledBodyCellState = {
	focus?: boolean;
	checkFailed?: boolean;
};
/** 可以做为标签化组件父组件的面板主体单元格组件内部组件 */
export type DeskPanelLabelledBodyCellComponents = { Box: StyledDIV };

/** 可以包含标签化组件的父组件接口 */
export interface LabellingParent {
	/** 返回当前组件的数据是否有必填要求 */
	isDataRequired(): boolean;

	/** 强制让被标签的组件获取焦点 */
	focusLabelled: () => void;
	/** 获取焦点后处理 */
	onFocusGained: () => void;
	/** 失去焦点后处理 */
	onFocusLost: () => void;
	/** 校验完毕后处理 */
	onCheckCompleted: (passed: boolean) => void;
}

/** 可以被标签化的组件属性ƒ */
export type LabellingComponentProps = {
	/** 标签化父节点 */
	labellingParent?: LabellingParent;
};
/** 按钮属性 */
export type DeskButtonProps = {
	label?: string;
} & WidgetProps &
	ButtonProps;
/** 按钮内部组件 */
export type DeskButtonComponents = {
	Button: StyledBUTTON & React.ComponentClass<ButtonProps>;
};
/** 链接属性 */
export type DeskLinkProps = {
	label?: string;
} & WidgetProps &
	Clickable;
/** 链接内部组件 */
export type DeskLinkComponents = {
	Link: StyledBUTTON & React.ComponentClass<Clickable>;
};
/** 按钮组内部组件 */
export type DeskButtonGroupComponents = { Box: StyledDIV };
/** 桌面标签内部组件 */
export type DeskLabelProps = { label?: string } & LabellingComponentProps &
	ModelWidgetProps &
	WidgetProps;
/** 桌面标签内部状态 */
export type DeskLabelState = { focus?: boolean };
/** 桌面标签内部组件 */
export type DeskLabelComponents = { Box: StyledSPAN };
/** 桌面作为组件标签出现的标签属性 */
export type DeskLabellingLabelProps = {
	required?: boolean;
} & DeskLabelProps &
	LabellingComponentProps;
export type DeskCodesLabelProps = {} & CodesWidgetProps & DeskLabelProps;
/** 桌面输入框属性 */
export type DeskTextProps = {} & LabellingComponentProps &
	CheckableWidgetProps &
	ReadonlyProps;
/** 桌面输入框内部组件 */
export type DeskTextComponents = {
	Box: StyledDIV;
	Text: StyledINPUT;
	ErrorReminder: any;
};
/** 桌面日期输入框属性 */
export type DeskDateProps = { format?: string } & DeskTextProps;
/** 桌面日期输入框内部组件 */
export type DeskDateComponents = {
	DisplayLabel: StyledSPAN;
	DisplayLabelCaret: StyledI;
} & DeskTextComponents;
/** 桌面下拉框属性 */
export type DeskSelectProps = {} & ReadonlyProps &
	LabellingComponentProps &
	CheckableWidgetProps &
	CodesWidgetProps;
/** 桌面下拉框内部组件 */
export type DeskSelectComponents = {
	Box: StyledDIV;
	Select: StyledSELECT;
	ErrorReminder: any;
};
/** 桌面勾选框属性 */
export type DeskCheckBoxProps = {} & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps;
/** 桌面勾选框内部组件 */
export type DeskCheckBoxComponents = {
	Box: StyledINPUT;
};
/** 桌面开关按钮属性 */
export type DeskToggleProps = {} & LabellingComponentProps &
	WidgetProps &
	ReadonlyProps;
/** 桌面开关按钮内部组件 */
export type DeskToggleComponents = {
	Box: StyledDIV;
	InnerBox: StyledDIV;
	Slider: StyledSPAN;
};
/** 桌面文件选择器属性 */
export type DeskFilePickerProps = { accept?: string } & DeskTextProps;
export type DeskFilePickerState = {
	fileName?: string;
	imageSource?: string;
} & CheckableWidgetState;
/** 桌面文件选择器内部组件 */
export type DeskFilePickerComponents = {
	PlaceHolder: StyledSPAN;
} & DeskTextComponents;
export type DeskImagePickerProps = { url: string } & DeskTextProps;
/** 桌面表格属性 */
export type DeskSpreadSheetProps = {
	/** 无数据显示CSS */
	noDataCss?: (props?: any) => string;
	/** 行显示CSS */
	rowCss?: (props?: any) => string;
} & WidgetProps;
/** 桌面表格内部组件 */
export type DeskSpreadSheetComponents = {
	Box: StyledDIV;
};
/** 桌面表格头内部组件 */
export type DeskSpreadSheetHeaderComponents = {
	Box: StyledDIV;
};
/** 桌面表格单元格属性 */
export type DeskSpreadSheetCellProps = {} & GridCellProps & ModelWidgetProps;
/** 桌面表格单元格内部组件 */
export type DeskSpreadSheetCellComponents = { Box: StyledDIV };
/** 桌面表格行集合属性 */
export type DeskSpreadSheetBodyRowsProps = {
	/** 是否描画无数据, 只有false才会关闭 */
	nodata?: { label?: boolean };
} & ModelWidgetProps;
/** 桌面表格行属性 */
export type DeskSpreadSheetBodyRowProps = {} & GridContainerProps;
/** 桌面表格行内部组件 */
export type DeskSpreadSheetBodyRowComponents = {
	Box: StyledDIV;
};
export type DeskSpreadSheetFooterProps = {};
export type DeskSpreadSheetFooterComponents = { Box: StyledDIV };
/** 桌面表格无数据属性 */
export type DeskSpeadSheetNoDataProps = {
	/** 显示标签 */
	label?: string | false;
} & ModelWidgetProps &
	FlexContainerProps;
/** 桌面表格无数据内部组件 */
export type DeskSpeadSheetNoDataComponents = {
	Box: StyledDIV;
};
/** 翻页对象 */
export type Pagination = {
	/** 从0开始计数 */
	pageNumber: number;
	pageCount: number;
	pageSize: number;
};
/** 翻页变动事件 */
export type PaginationChangeEvent = Pagination & {
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type DeskPaginationProps = {
	onPageChanged?: (evt: PaginationChangeEvent) => void;
} & ModelWidgetProps;
/** 桌面分页内部组件 */
export type DeskPaginationComponents = {
	Box: StyledDIV;
	Arrow: any;
	Button: StyledBUTTON;
	TotalCount: StyledDIV;
};
// 桌面组件 *******************************************************
export type OtherSmallImageCreator = (url: string) => any;
export type OtherSmallImageArrayOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	item: any;
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type OtherSmallImageArrayOperator = (
	options: OtherSmallImageArrayOptions
) => void;
export type OtherSmallImageFileCountOperator = (
	options: OtherSmallImageArrayOptions
) => number;
export type OtherSmallFileCreator = (url: string) => any;
export type OtherSmallFileArrayOptions = {
	model: any;
	root: any;
	arrayHolder: any;
	item: any;
	invoker: ModelWidgetInterface & WidgetHelped;
};
export type OtherSmallFileArrayOperator = (
	options: OtherSmallImageArrayOptions
) => void;
export type OtherSmallFileFileCountOperator = (
	options: OtherSmallImageArrayOptions
) => number;
export type OtherIssueSmallImageArrayOptions = {
    // model: any;
    // root: any;
    // arrayHolder: any;
    // item: any;
    invoker: ModelWidgetInterface & WidgetHelped;
};
export type OtherIssueSmallImageArrayOperator = (
    options: OtherIssueSmallImageArrayOptions
) => void;
// 组件 ********************************************

// 系统健康 ********************************************
export type ActuatorResultProps = {
	data?: any | null;
} & WidgetProps;
export type ActuatorResultComponents = {
	Box: StyledDIV;
};
export type ActuatorBodyState = {
	data?: any | null;
};
export type LogDownloadComponents = {
	ContentBox: StyledDIV;
} & DeskPageBodyComponents;
export type LogLevelComponents = {
	ContentBox: StyledDIV;
	Row: StyledDIV;
	Category: StyledINPUT;
	Level: StyledSELECT;
	Confirm: StyledBUTTON;
} & DeskPageBodyComponents;
// 系统健康 ********************************************

// 登录 ********************************************
export type SignCommonPageComponents = {
	TopBlank: StyledDIV;
	Form: StyledDIV;
	InputRow: StyledDIV &
	React.ComponentClass<{
		focus?: boolean;
	}>;
	Icon: StyledI & React.ComponentClass<FaIconProps>;
	Input: StyledINPUT & React.ComponentClass<InputProps>;
	Row: StyledDIV;
	SignBtn: StyledBUTTON;
	Link: StyledBUTTON;
	RegisterRow: StyledDIV;
} & PageComponents;

export interface SignCommonPageProps extends StyledProps {
	focus?: boolean;
}

export type SignCommonPageState = {
	focus?: React.RefObject<HTMLDivElement>;
};

export enum FirstProductType {
	/** 财产险 */
	PROPERTY = '1',
	/** 人身险 */
	PERSONAL = '2',
	/** 健康险 */
	HEALTH = '3',
	/** 意外险 */
	ACCIDENT = '4',
}