import { Consts, Envs, Storage, Utils } from '@common';
import { History, React, Route, Router, StyledF, Switch } from '@common/3rd';
import { Application, StyledPropsTheme } from '@my-types';
import Styles from '@styles';
import MiddlePage from './middle-page';



let dialogOrAlertShownCount: number = 0;

/**
 * 应用入口
 */
class App extends React.Component implements Application {
	history: History.History;
	windowScrollY: number = 0;
	scrollDisabled: boolean = false;
	state: {
		themeName: string;
		globalStyles: React.ComponentClass<any>;
		theme: StyledPropsTheme;
	};

	constructor(props: any, context?: any) {
		super(props, context);
		this.history = History.createBrowserHistory({
			basename: process.env.REACT_APP_AJAX_CLIENT_CONTEXT || '/csms'
		});
		const themeName = Envs.getCurrentTheme();
		this.state = {
			themeName: themeName,
			globalStyles: Styles.createGlobalStyles(themeName),
			theme: Styles.getTheme(themeName)
		};
	}



	componentDidMount() {
		Envs.application(this);

		// 设备转向, 重刷全局样式表
		window.addEventListener('orientationchange', () => {
			setTimeout(() => {
				this.setState({
					globalStyles: Styles.createGlobalStyles(this.state.themeName)
				});
			}, 30);
		});
	}

	componentWillUnmount() {
		Envs.application(null);
	}

	private renderGlobalStyle(): JSX.Element {
		const GlobalStyles = this.state.globalStyles;
		return <GlobalStyles />;
	}


	render() {
		return (
			<React.Fragment>
				{this.renderGlobalStyle()}
				<StyledF.ThemeProvider theme={this.getTheme()}>
					<React.Suspense fallback={<div />}>
						<Router history={this.history}>
							<Switch>
								<Route path="/" render={() => <MiddlePage />} />
								<Route path="/*" render={() => <MiddlePage />} />
							</Switch>
						</Router>
					</React.Suspense>
				</StyledF.ThemeProvider>
			</React.Fragment>
		);
	}

	/**
	 * 获取history对象
	 */
	getHistory(): History.History {
		return this.history;
	}

	/**
	 * 获取当前主题
	 */
	getTheme(): StyledPropsTheme {
		return this.state.theme;
	}

	/**
	 * 获取当前主题名称
	 */
	getThemeName(): string {
		return this.state.themeName;
	}

	/**
	 * 更改主题
	 */
	changeTheme(name: string): void {
		// 没有设置, 或者原始值相等
		if (!name || name === this.state.themeName) {
			return;
		}

		if (name.startsWith('style-')) {
			name = name.substring(6);
		}

		// 有可能截取过了, 再次比较
		if (name === this.state.themeName) {
			return;
		}

		// 保存到Storage中
		Storage.Theme.set(Consts.THEME_KEY, name);
		this.setState({
			gloablStyle: Styles.createGlobalStyles(name),
			theme: Styles.getTheme(name),
			themeName: name
		});
	}

	changeThemeColor(themeColor?: string) {
		if (Utils.isBlank(themeColor)) {
			themeColor = Storage.Theme.session().get(Consts.THEME_COLOR_KEY);
		}
		if (!Utils.isBlank(themeColor)) {
			// 保存到Storage中
			Storage.Theme.session().set(Consts.THEME_COLOR_KEY, themeColor);
			let name = Envs.getCurrentTheme() || 'standard';
			this.setState({
				gloablStyle: Styles.createGlobalStyles(name),
				theme: Object.assign(Styles.getTheme(name), {
					COLOR_PRIMARY: themeColor,
					COLOR_PRIMARY_LIGHT_O75: `rgba(${StyledF.hexToRgba(themeColor).join(',')}, 0.75)`, // 标准色75%透明
					COLOR_PRIMARY_LIGHT_010: `rgba(${StyledF.hexToRgba(themeColor).join(',')}, 0.1)`,
					COLOR_PRIMARY_LIGHT: StyledF.colorLightenDarken(themeColor, 20), // 标准色淡10%
					INPUT_FOCUS_BORDER_COLOR: `rgba(${StyledF.hexToRgba(themeColor).join(',')}, 0.8)`, // 输入框获取焦点后边框色
					TOGGLE_TRUE_BORDER_COLOR: themeColor,
					TOGGLE_TRUE_BACKGROUND_COLOR: themeColor
				}),
				// themeName: name
			});
		}
	}

	setScrollDisabled(disabled: boolean): void {
		if (disabled) {
			// dialogOrAlertShownCount += 1;
			//连续点击两次会有问题，改成使用固定1
			dialogOrAlertShownCount = 1;
		} else {
			dialogOrAlertShownCount = Math.max(dialogOrAlertShownCount - 1, 0);
		}
		this.scrollDisabled = dialogOrAlertShownCount > 0;
		Utils.log('DialogOrAlertShownCount:', dialogOrAlertShownCount);
		const isBodyFixed = document.body.style.position === 'fixed';
		if (disabled && (dialogOrAlertShownCount === 1 || (dialogOrAlertShownCount > 1 && !isBodyFixed))) {
			// 第一个出现, 锁死body
			// 拿到当前的scrollY值
			const scrollY = (this.windowScrollY = window.scrollY);
			document.documentElement.style.overflowY = 'hidden';
			// 设置body向上定位, 并且定住top. 规避掉body可以滚动的问题
			document.body.style.top = `-${scrollY}px`;
			if (!Envs.isPhone()) {
				document.documentElement.style.paddingRight = `${this.getTheme().DESK_HTML_HORIZONTAL_MARGIN}px`;
				document.body.style.paddingRight = `${this.getTheme().DESK_HTML_HORIZONTAL_MARGIN / 2}px`;
			}
			document.body.style.position = 'fixed';
		} else if (dialogOrAlertShownCount === 0) {
			document.documentElement.style.overflowY = 'scroll';
			document.documentElement.style.paddingRight = '';
			// 全部消失, 回退到第一个出现之前的状态
			const scrollY = this.windowScrollY;
			// 清掉body的css
			document.body.style.top = '';
			document.body.style.paddingRight = '';
			document.body.style.position = '';
			// 将window的scrollY移过去, 视觉上保持位置不懂
			window.scrollTo(0, scrollY);
			this.windowScrollY = 0;
		}
	}

	isScrollDisabled(): boolean {
		return this.scrollDisabled;
	}
}

export default App;
