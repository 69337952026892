import { StyledPropsCss } from '../../types';
import { React, Styled, StyledF } from '../../common/3rd';
// import { StyledComponents } from '../../styles';
// @ts-ignore
import eBaoCloudLogo from '../../images/logo_eBaoCloud-light_blue.png';

const Pulse = StyledF.keyframes`
	0%, 96%, 98%, 100% {
		transform: scale3d(1, 1, 1);
	}
	97%, 99% {
		transform: scale3d(1.03, 1.03, 1.03);
	}
`;

const Box = Styled.div.attrs({
	'data-widget': 'logo'
})`
	text-align: center;
	height : 120px;
	animation: ${Pulse} linear 10s infinite;
	background-image: url(${eBaoCloudLogo});
	background-size: 150px;
	background-repeat: no-repeat;
	background-position-x:center;
	background-position-y:center;
	i {
		display: block;
		position: absolute;
		width: 1.3em;
	}

	i:first-child {
		color: ${props => props.theme.COLOR_PRIMARY};
		left: 50%;
		transform: translateX(-58.333333%);
	}
	i:nth-child(2) {
		position: relative;
		margin: auto;
		color: ${props => props.theme.COLOR_REVERSED};
		transform: scale(0.98);
	}
	i:last-child {
		color: ${props => props.theme.COLOR_PRIMARY};
		left: 50%;
		transform: translateX(-41.666667%);
		top: 0;
	}

	${(props: StyledPropsCss) => (props.css ? props.css(props, (props as any).theme) : '')}
`;

/**
 * LOGO
 */
class Logo extends React.Component<StyledPropsCss> {
	render() {
		return (
			<Box {...this.props}>
				{/* <StyledComponents.FAS icon="cloud" />
				<StyledComponents.FAS icon="cloud" />
				<StyledComponents.FAS icon="cloud" /> */}
			</Box>
		);
	}
}

/**
 * LOGO 96px字体以及高度
 */
class Logo96 extends React.Component {
	render() {
		return (
			<Logo
				css={() => {
					return `
						font-size: 96px;
					`;
				}}
			/>
		);
	}
}

export default Logo;
export { Logo96 };
