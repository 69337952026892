import { StyledPropsCss } from '../../types';
import { Styled } from '../../common/3rd';

const Input = Styled.input`
	display: block;
	position: relative;
	border: ${props => props.theme.BORDER};
	border-radius: ${props => props.theme.BORDER_RADIUS}px;
	outline: none;
	margin: 0;
	background-color: ${props => props.theme.INPUT_BACKGRAOUND_COLOR};
	color: ${props => props.theme.FONT_COLOR};
	font-family: ${props => props.theme.FONT_FAMILY};
	font-weight: ${props => props.theme.FONT_WEIGHT};
	font-size: inherit;
	padding: ${props => props.theme.INPUT_VERTICAL_PADDING}px ${props =>
	props.theme.INPUT_HORIZONTAL_PADDING}px;
	height: ${props => props.theme.INPUT_HEIGHT}px;
	line-height: ${props =>
		props.theme.INPUT_HEIGHT -
		props.theme.INPUT_VERTICAL_PADDING * 2 -
		props.theme.BORDER_WIDTH * 2}px;
	appearance: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;

	&[disabled],
	&[readOnly],
	&[readonly] {
		background-color: ${props => props.theme.COLOR_DISABLED};
	}

	${(props: StyledPropsCss) =>
		props.css ? props.css(props, (props as any).theme) : ''}}
`;

const InputUnderline = Styled(Input)`
	border-top-color: transparent;
	border-left: 0;
	border-right: 0;
	padding-left: 0;
	padding-right: 0;
	border-radius: 0;
	&:focus {
		border-bottom-color: ${props => props.theme.COLOR_PRIMARY};
	}
`;

export const INPUT = Input;
export const InputUL = InputUnderline;
